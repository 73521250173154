import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  Paper,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

const headersData = [
  {
    label: "Home",
    href: "https://powerofchoiceusa.com",
  },
  // {
  //   label: "Renewable",
  //   href: "/renewable",
  // },
  // {
  //   label: "Power",
  //   href: "/power",
  // },
  // {
  //   label: "About",
  //   href: "/about",
  // },
  {
    label: "Contact us",
    href: "/contact-us",
  },
  {
    label: "Disclosures",
    href: "/disclosures",
  },
];

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    padding: "22px 0px",
    "@media (max-width: 900px)": {
      padding: "5px",
      borderBottom: "2px solid #d40030"
    },
  },
  logo: {
    "@media (max-width: 900px)": {
      height: "40px",
    },
  },
  menuLink: {
    color: "#0d235f",
    fontFamily: "Open Sans, sans-serif",
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: "normal",
    size: "18px",
    "-webkit-transition": "color 0.5s ease-out",
    "-moz-transition": "color 0.5s ease-out",
    "-o-transition": "color 0.5s ease-out",
    transition: "color 0.5s ease-out",
    "&:hover": {
      color: "#d40030",
      "text-decoration": "none",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawer: {},
  drawerContainer: {
    padding: "20px 30px",
  },
  navBarButton: {
    color: "#d40030",
    position: "absolute",
    right: 0,
  },
}));

export default function Header() {
  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={classes.toolbar}>
        {logo_image}
        <div>{getMenuLinks()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <div>
        <Drawer
          className={classes.drawer}
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={classes.drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        <Toolbar>
          <div>{logo_image}</div>
          <IconButton
            className={classes.navBarButton}
            {...{
              edge: "start",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </div>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <MenuItem
          key={label}
          className={classes.menuLink}
          component={RouterLink}
          to={href}
        >
          {label}
        </MenuItem>
      );
    });
  };

  const logo_image = (
    <Paper elevation={0}>
      <img
        className={classes.logo}
        alt="Power of choice"
        src="power-of-choice-logo.png"
      />
    </Paper>
  );

  const getMenuLinks = () => {
    return headersData.map(({ label, href },key) => 
        <Link key={key} className={classes.menuLink} href={href}>
          {label}
        </Link>
    );
  };

  return (
    <header>
      <AppBar position={"static"} elevation={0} className={classes.header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
