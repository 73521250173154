import { FormControl, Radio, RadioGroup, Divider, Collapse, TextField, Checkbox, FormControlLabel, InputLabel, Select, MenuItem } from '@mui/material'
import { DesktopDatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { DesktopDatePicker } from '@mui/lab';
import React, { useEffect } from 'react'
import "./../../styles/form.css"
import { FormHelperText } from '@material-ui/core';
import { get_date } from "./../../functions/DateController"


var maxDate = new Date;
maxDate.setMonth(maxDate.getMonth() + 8);
const todayDate = Date.now();

function AboutChampions(props) {

  const { components } = props;
  const { onChangeFirstName, onChangeLastName, onChangeAgentId, onChangeDOB, onChangeSSN, onChangeEmail, onChangePrimaryPhone, onChangeAlternatePhone, onChangeLanguagePreference, onChangeIsDifferenteBillingCity, onChangeAddress1, onChangeAddress2, onChangeCity, onChangeESIID, onChangeBillingAddress1, onChangeBillingAddress2, onChangeBillingCity, onChangeBillingStateCode, onChangeBillingZipCode, onChangeIsRenew, onChangeEbill, onChangeRequestedDate, onChangeSignupType } = props.functions;
  const { ZipCode, StateCode, ProviderId } = props.functions;


  const fullwidth = { gridColumn: "1/9" }, firstPart = { gridColumn: "1/5" }, secondPart = { gridColumn: "5/9" }, twoOfThree = { gridColumn: "1/7" }, threeOfTwo = { gridColumn: "7/9" }, firstThird = { gridColumn: "1/4" }, secondThird = { gridColumn: "4/7" }, thirdThird = { gridColumn: "7/9" };

  const [DOB, setDOB] = React.useState(null);

  const [LanguagePreference, setLanguagePreference] = React.useState(components.LanguagePreference.value);
  const [RequestedDate, setRequestedDate] = React.useState(null);
  const [ShowSecondBillingAddress, setShowSecondBillingAddress] = React.useState(false);

  const [IsRenew, setIsRenew] = React.useState(components.IsRenew.value);
  const [BillMethod, setBillMethod] = React.useState(components.Ebill.value);
  const [IsDifferenteBillingCity, setIsDifferenteBillingCity] = React.useState(false)
  const [Address1, setAddress1] = React.useState(components.Address1.value);
  const [Address2, setAddress2] = React.useState(components.Address2.value);
  const [City, setCity] = React.useState(components.City.value);

  const [ESIID, setESIID] = React.useState(components.ESIID.value);
  const [ESIIDOptions, setESIIDOptions] = React.useState([]);
  const [OpenESIID, setOpenESIID] = React.useState(false);
  const LoadingESIID = OpenESIID && ESIIDOptions.length === 0;


  const secondBillingAddressContainer = React.useRef(null);
  const BillingCityContainer = React.useRef(null);

  const changeRequestedDate = (date) => {
    onChangeRequestedDate(date)
    setRequestedDate(date)
  }

  const fillBillingAddress = (event) => {
    setShowSecondBillingAddress((event.target.value).length > 4)
    onChangeBillingAddress1(event)
  }

  const changeBillMethod = (event) => {
    onChangeEbill(event)
    setBillMethod(event.target.value)
  }

  const changeIsRenew = (event) => {
    setIsRenew(event.target.checked)
    onChangeIsRenew(event.target.checked ? 1 : 0);
  }


  const changeDOB = (date) => {
    onChangeDOB(date)
    setDOB(date)
  }

  const changeLanguagePreference = (event) => {
    onChangeLanguagePreference(event)
    setLanguagePreference(event.target.value);
  }

  return (
    <FormControl id="grid_ChampionsForm">
      <h2 id="title" style={fullwidth}>{"Your Contact Details"}</h2>
      <Divider id='m-divider' style={fullwidth} />
      <TextField
        select={props.disabled}
        disabled={props.disabled}
        style={firstPart}
        defaultValue={components.FirstName.value}
        required={components.FirstName.required}
        error={components.FirstName.error}
        onChange={onChangeFirstName}
        helperText={components.FirstName.helperText}
        label={components.FirstName.label}
      />
      <TextField
        disabled={props.disabled}
        style={secondPart}
        defaultValue={components.LastName.value}
        required={components.LastName.required}
        error={components.LastName.error}
        onChange={onChangeLastName}
        helperText={components.LastName.helperText}
        label={components.LastName.label}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={props.disabled}
          label={components.DOB.label}  
          value={DOB}
          openTo="year"
          views={['year', 'month', 'day']}
          error={components.DOB.error}
          // maxDate={get_date(-18)}
          
          onChange={changeDOB}
          renderInput={(params) => <TextField disabled={props.disabled}  required={components.DOB.required} helperText={components.DOB.helperText} error={components.DOB.error} {...params} style={fullwidth} />}
        />
      </LocalizationProvider>
      <TextField
        disabled={props.disabled}
        style={fullwidth}
        defaultValue={components.Email.value}
        required={components.Email.required}
        error={components.Email.error}
        onChange={onChangeEmail}
        helperText={components.Email.helperText}
        label={components.Email.label}
      />
      <TextField
        disabled={props.disabled}
        style={fullwidth}
        defaultValue={components.PrimaryPhone.value}
        required={components.PrimaryPhone.required}
        error={components.PrimaryPhone.error}
        onChange={onChangePrimaryPhone}
        helperText={components.PrimaryPhone.helperText}
        label={components.PrimaryPhone.label}
      />

      <FormControl style={firstPart}>
        <InputLabel>{components.LanguagePreference.label}</InputLabel>
        <Select
          disabled={props.disabled}
          value={LanguagePreference}
          defaultValue={components.LanguagePreference.value}
          label={components.LanguagePreference.label}
          onChange={changeLanguagePreference}
          error={components.LanguagePreference.error}
        >
          <MenuItem value={0}>English</MenuItem>
          <MenuItem value={1}>Spanish</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={secondPart}>
        <InputLabel>{components.Ebill.label}</InputLabel>
        <Select
          disabled={props.disabled}
          value={BillMethod}
          defaultValue={components.Ebill.value}
          label={components.Ebill.label}
          onChange={changeBillMethod}
          error={components.Ebill.error}
        >
          <MenuItem value={1}>Email</MenuItem>
          <MenuItem value={0}>Mail</MenuItem>
        </Select>

      </FormControl>



      <FormControl style={{ ...fullwidth }}>
        <FormControlLabel
          disabled={props.disabled}
          checked={IsDifferenteBillingCity}
          defaultChecked={IsDifferenteBillingCity}
          label={"Is the billing address different from the service address? "}
          onChange={(event) => {
            setIsDifferenteBillingCity(event.target.checked)
            onChangeIsDifferenteBillingCity(event.target.checked)
          }}
          control={<Checkbox />} />
      </FormControl>



      <Collapse orientation="down" style={{ ...fullwidth, marginBottom: "5px" }} in={IsDifferenteBillingCity} container={BillingCityContainer.current} collapsedSize={0}>
        <div id="grid_ChampionsForm" style={{ width: "100%" }} ref={BillingCityContainer}>
          <h3 id="title" style={fullwidth}>Billing Address</h3>
          <Divider id='m-divider' style={fullwidth} />

          <TextField
            disabled={props.disabled}
            style={fullwidth}
            defaultValue={components.BillingAddress1.value}
            required={components.BillingAddress1.required}
            error={components.BillingAddress1.error}
            onChange={fillBillingAddress}
            helperText={components.BillingAddress1.helperText}
            label={components.BillingAddress1.label}
          />
          <Collapse orientation="down" style={fullwidth} in={ShowSecondBillingAddress} container={secondBillingAddressContainer.current} collapsedSize={0}>
            <TextField
              disabled={props.disabled}
              ref={secondBillingAddressContainer}
              style={{ width: "100%" }}
              defaultValue={components.BillingAddress2.value}
              required={components.BillingAddress2.required}
              error={components.BillingAddress2.error}
              onChange={onChangeBillingAddress2}
              helperText={components.BillingAddress2.helperText}
              label={components.BillingAddress2.label}
            />
          </Collapse>

          <TextField
            disabled={props.disabled}
            style={firstThird}
            defaultValue={components.BillingCity.value}
            required={components.BillingCity.required}
            error={components.BillingCity.error}
            onChange={onChangeBillingCity}
            helperText={components.BillingCity.helperText}
            label={components.BillingCity.label}
          />
          <TextField
            disabled={props.disabled}
            style={secondThird}
            defaultValue={components.BillingStateCode.value}
            required={components.BillingStateCode.required}
            error={components.BillingStateCode.error}
            onChange={onChangeBillingStateCode}
            helperText={components.BillingStateCode.helperText}
            label={components.BillingStateCode.label}
          />
          <TextField
            disabled={props.disabled}
            style={thirdThird}
            defaultValue={components.BillingZipCode.value}
            required={components.BillingZipCode.required}
            error={components.BillingZipCode.error}
            onChange={onChangeBillingZipCode}
            helperText={components.BillingZipCode.helperText}
            label={components.BillingZipCode.label}
          />
        </div>

      </Collapse>

      <h2 style={{ textAlign: "center", ...fullwidth }}>{"Are you moving or switching providers? (Choose one)"}</h2>
      <RadioGroup
        style={{ ...fullwidth, marginBottom: "13px", width: "70%", marginLeft: "auto", marginRight: "auto" }}
        defaultValue={components.SignupType.value}
        label={components.SignupType.label}
        onChange={onChangeSignupType}
        error={components.SignupType.error}
      >
        <FormControlLabel style={{ marginLeft: 20 }} value={1} control={<Radio disabled={props.disabled} />} label="I am MOVING and need new service" />
        <FormControlLabel style={{ marginLeft: 20 }} value={0} control={<Radio disabled={props.disabled} />} label="Switching from another provider" />
        <FormControlLabel style={{ marginLeft: 20 }} value={2} control={<Radio disabled={props.disabled} />} label="Standard Switching" />

      </RadioGroup>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={components.RequestedDate.label}
          value={RequestedDate}
          disabled={props.disabled}
          disablePast
          disableHighlightToday
          maxDate={maxDate}
          openTo="month"
          views={['month', 'day']}
          minDate={todayDate}
          onChange={changeRequestedDate}
          renderInput={(params) => <TextField disabled={props.disabled} required={components.RequestedDate.required} {...params} style={{ ...fullwidth, width: "70%", margin: "auto" }} />}
        />
        {components.RequestedDate.error ? <FormHelperText error style={{ ...fullwidth, width: "70%", margin: "auto" }} >{components.RequestedDate.helperText}</FormHelperText> : null}
      </LocalizationProvider>

      <FormControl style={{ ...fullwidth, width: "70%", margin: "auto" }}>
        <FormControlLabel
          disabled={props.disabled}
          style={thirdThird}
          defaultChecked={components.IsRenew.value}
          label={components.IsRenew.label}
          control={<Checkbox
            checked={IsRenew}
            error={components.IsRenew.error}
            onChange={changeIsRenew} />} />
      </FormControl>



      <h3 id="title" style={fullwidth}>Confirm Your Identity</h3>
      <TextField
        disabled={props.disabled}
        style={{ ...fullwidth }}
        defaultValue={components.SSN.value}
        required={components.SSN.required}
        error={components.SSN.error}
        onChange={onChangeSSN}
        helperText={components.SSN.helperText}
        label={components.SSN.label}
      />



      <p style={fullwidth}>You should receive a copy of all documents by US mail or email within 2 weeks. You can request an additional copy to be sent to you at any time. You may also print a copy of these documents now by clicking on the links above.</p>









    </FormControl>

  )

}

export { AboutChampions }