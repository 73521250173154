import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { FormControl, RadioGroup, FormControlLabel, Radio, TextField, Checkbox, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import axios from 'axios';
import { Alert } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import { Paper, Container, Grid } from "@material-ui/core";
import { CircularProgress } from "@mui/material";



const getAddress = async (zip, tdu, st_number, st_name, abort) => {

    // controller.abort()

    let addresses = await axios.get(`https://powerusapi.herokuapp.com/address/energytexas/aux`, {
        signal: abort,
        params: {

            street_number: '',
            street_name: st_name,
            tdu_area: tdu,
            //Address2:'',
            // City: '',
            //StateCode: "TX",
            zip_code: zip
        }
    })
    return addresses.data
}

const steps = ["SELECT", "DETAILS"];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const get_date = (months) => {
    //months= 1 ->current date
    //months > 1 current date + n months
    var d = new Date(),
        month = '' + (d.getMonth() + months),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');

}

const StatusModal = (props) => {
    const [open, setOpen] = React.useState(props.status.show);
    const navigation = useNavigate();

    const handleClose = () => {
        props.setShowStatusModal((showStatusModal) => ({ ...showStatusModal, show: false, success: false, errors: {} }))
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                disableEscapeKeyDown={true}
            >
                <DialogTitle >{"Enrollment"}</DialogTitle>
                <DialogContent>
                    <DialogContentText component={'span'} id="alert-dialog-slide-description">
                        {props.status.loading?<div style={{textAlign:'center'}}><CircularProgress style={{ marginLeft:100, marginRight:100}}/></div>:
                        props.status.success ?
                        <Alert severity="success">
                            <AlertTitle>Success</AlertTitle>
                            If you need further assistance, please call us at 713 358 5479
                        </Alert>
                        :
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            Please call us at 713 358 5479 for further assistance
                            <ul>
                                {Object.values(props.status.errors).map((e, key) => {
                                    return (<li key={key}>{e}</li>)
                                })}

                            </ul>

                        </Alert>
                    
                        
                        }
                        {/* {props.status.success ?
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                Your information has been sent
                            </Alert>
                            :
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                A problem has been ocurred
                                <ul>
                                    {Object.values(props.status.errors).map((e, key) => {
                                        return (<li key={key}>{e}</li>)
                                    })}

                                </ul>

                            </Alert>
                        } */}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.status.success ?
                        <Button onClick={() => { navigation(`/`) }}>Close</Button>
                        :
                        <Button onClick={handleClose}>Close</Button>
                    }

                </DialogActions>
            </Dialog>
        </div>
    );
}

const StartDate = (props) => {
    var controller = new AbortController();

    const { aboutForm, setAboutForm, handlersAbout, summary, zip } = props
    const [adrss, setAdrss] = useState([])
    const [cancelAxios, setCancelAxios] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [loadingAddr, setLoadingAddr] = useState(false)
    const [selectAddrss, setSelectAddrss] = useState([])

    const handleAutoCompleteTyping = (e) => {
        let value = e.target.value

        if (value.length > 3 && !cancelAxios) {
            setLoadingAddr(true)
            setCancelAxios(true)
            getAddress(zip, summary.utility_name, '', value, controller.signal).then(addresses => {
                setAdrss(addresses)
                setNotFound(addresses.length == 0)
                setLoadingAddr(false)
            }).finally(() => {
                setCancelAxios(false)
            })
        }

    }
    const handleAutoComplete = (e, obj) => {
        selectAddrss.pop()
        setSelectAddrss(obj)
        setAdrss([])

        let valor = obj[0] || null
        let address = valor != null ? valor.service_address : ''
        let esiid = valor != null ? valor.esiid : ''
        let city = valor != null ? valor.city : ''

        setAboutForm((value) => ({
            ...value,
            ["service_address"]: address,
            ["esiid"]: esiid,
            ["city"]: city,
            ["zip"]: zip
        }))

    }

    return (
        <Paper style={{ height: "auto", margin: "auto", padding: 20, position: "relative" }} className={"card"} variant="outlined">
            {/* <div style={{ maxWidth: 700, height: "auto", margin: "auto", padding: 10, position: "relative" }}> */}
            <div style={{}}>
                <h3 style={{ textAlign: "center" }}>Where's The Power Goin'?</h3>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Autocomplete
                                id="service_address"
                                name="service_address"
                                freeSolo
                                options={adrss}
                                getOptionLabel={(option) => option.service_address || ''}
                                onChange={handleAutoComplete}
                                multiple
                                renderInput={(params) =>
                                    <TextField {...params}
                                        error={aboutForm.errorMessage.service_address || notFound}
                                        id="outlined-basic"
                                        required
                                        onKeyDown={(event) => {
                                            if (selectAddrss.length == 1) {
                                                event.preventDefault()
                                            }

                                        }}
                                        label="Service Address"
                                        name="service_address"
                                        onChange={handleAutoCompleteTyping}
                                        helperText={notFound ? "ADDRESS IS NOT FOUND. PLEASE CALL 803-361-3139" : null}
                                        //{aboutForm.service_address}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingAddr ? <CircularProgress color="inherit" size={35} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                        variant="outlined" />}
                            />

                        </Grid>
                        
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField fullWidth disabled error={aboutForm.errorMessage.city} required id="outlined-basic" label="City" name="city" value={aboutForm.city} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField fullWidth disabled error={aboutForm.errorMessage.state} required id="outlined-basic" label="State" name="state" value={aboutForm.state} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField fullWidth disabled error={aboutForm.errorMessage.zip} required id="outlined-basic" label="Zip Code" name="state" value={zip} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth disabled error={aboutForm.errorMessage.esiid} required id="outlined-basic" label="ESIID" name="esiid" value={aboutForm.esiid} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Paper>

    )
}


const About = (props) => {
    const { aboutForm, showOption, handlersAbout } = props

    return (
        <Paper style={{ height: "auto", margin: "auto", padding: 20, position: "relative" }} className={"card"} variant="outlined">
            <div style={{ paddingTop: 0 }}>
                <h2 style={{ textAlign: "center" }}>Your Contact Details</h2>
                <Grid container spacing={2}>
                    <Grid fullWidth item xs={12} md={6} lg={6}>
                        <TextField fullWidth error={aboutForm.errorMessage.first_name} helperText={aboutForm.errorMessage.first_name} required id="outlined-basic" label="First Name" name="first_name" value={aboutForm.first_name} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField fullWidth error={aboutForm.errorMessage.last_name} helperText={aboutForm.errorMessage.last_name} required id="outlined-basic" label="Last Name" name="last_name" value={aboutForm.last_name} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField fullWidth error={aboutForm.errorMessage.email_address} helperText={aboutForm.errorMessage.email_address} type={"email"} required id="outlined-basic" label="Email Address" value={aboutForm.email_address} name="email_address" onChange={handlersAbout.handleTexflieds} variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField fullWidth required error={aboutForm.errorMessage.phone_number} helperText={aboutForm.errorMessage.phone_number} inputProps={{ maxLength: 10 }} maxRows={10} id="outlined-basic" label="Phone Number" value={aboutForm.phone_number} name="phone_number" onChange={handlersAbout.handleTexflieds} variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={aboutForm.billing_same_as_service} onChange={handlersAbout.handleBillingSameAsService} />} label="Billing Address is same as service address" />
                    </Grid>
                    {!aboutForm.billing_same_as_service ?
                        <>
                            <Grid item xs={12}>
                                <TextField fullWidth error={aboutForm.errorMessage.billing_address} helperText={aboutForm.errorMessage.billing_address} id="outlined-basic" label="Billing Address" name="billing_address" value={aboutForm.billing_address} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField fullWidth error={aboutForm.errorMessage.billing_city} helperText={aboutForm.errorMessage.billing_city} required id="outlined-basic" label="City" value={aboutForm.billing_city} onChange={handlersAbout.handleTexflieds} name="billing_city" variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField fullWidth error={aboutForm.errorMessage.billing_zip} helperText={aboutForm.errorMessage.billing_zip} required id="outlined-basic" label="Zip" value={aboutForm.billing_zip} name="billing_zip" onChange={handlersAbout.handleTexflieds} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField fullWidth error={aboutForm.errorMessage.billing_state} helperText={aboutForm.errorMessage.billing_state} required id="outlined-basic" label="State" name="billing_state" value={aboutForm.billing_state} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                            </Grid>
                        </>
                        :
                        ''}
                </Grid>
                <div style={{ marginBottom: 50 }}>
                    <FormControl>
                        <h2>Are you moving or switching providers? (Choose one)</h2>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={aboutForm.movingSwitching}
                            onChange={handlersAbout.handleMovingSwitching}
                        >
                            <FormControlLabel style={{ marginLeft: 20 }} value="moving" control={<Radio />} label="I am MOVING and need new service" />
                            <FormControlLabel style={{ marginLeft: 20 }} value="switching" control={<Radio />} label="I am SWITCHING my current service to Energy Texas" />
                            {showOption.switchingDetails ?
                                <RadioGroup name="radio-buttons-group" value={aboutForm.switchingDetails} onChange={handlersAbout.handleSwitchDetails}>
                                    <FormControlLabel style={{ marginLeft: 40 }} value="asap" control={<Radio />} label="I want to switch as soon as possible (within 1 to 7 days)" />
                                    <FormControlLabel style={{ marginLeft: 40 }} value="switchDate" control={<Radio />} label="I want to select my switch date" />
                                </RadioGroup>
                                : ''
                            }
                        </RadioGroup>
                        {showOption.startDate ?
                            <FormGroup style={{ padding: 20 }}>
                                <TextField
                                    id="date"
                                    error={aboutForm.errorMessage.start_date}
                                    helperText={aboutForm.errorMessage.start_date}
                                    label="Start Date"
                                    type="date"
                                    name="start_date"
                                    value={aboutForm.start_date}
                                    onChange={handlersAbout.handleTexflieds}
                                    inputProps={{ min: get_date(1), max: get_date(5) }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                            </FormGroup>
                            : ''}

                    </FormControl>
                </div>
                <div style={{ marginBottom: 50 }}>
                    {/* <FormGroup style={{ margin: 20 }}>
                        <FormControlLabel control={<Checkbox checked={aboutForm.autoPay} onChange={handlersAbout.handleAutoPay} />} label="Auto Pay" />
                    </FormGroup>
                    {aboutForm.autoPay && 
                    <FormControl>
                        <h2>Payment</h2>
                        <div>
                        <FormControl style={{ margin: 20 }}>
                            <TextField error={aboutForm.errorMessage.name_on_card} required id="outlined-basic" label="Name on card" name="name_on_card" value={aboutForm.name_on_card} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </FormControl>
                        <FormControl style={{ margin: 20 }}>
                            <TextField error={aboutForm.errorMessage.card_number} required id="outlined-basic" label="Credit Card Number" name="card_number" value={aboutForm.card_number} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </FormControl>
                        <FormControl style={{ margin: 20 }}>
                            <TextField error={aboutForm.errorMessage.cvv} type="password" required id="outlined-basic" label="CVV Number" name="cvv" value={aboutForm.cvv} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </FormControl>
                        <FormControl style={{ margin: 20, display:"inline-block" }}>
                            <TextField style={{width:112}} error={aboutForm.errorMessage.exp_month} placeholder={'MM'} required id="outlined-basic" label="Exp Month" name="exp_month" value={aboutForm.exp_month} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                            <FormLabel style={{fontSize:40}} > / </FormLabel>
                            <TextField style={{width:112}} error={aboutForm.errorMessage.exp_year} placeholder={'YY'} required id="outlined-basic" label="Exp Year" name="exp_year" value={aboutForm.exp_year} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        </FormControl>
                        </div>
                    </FormControl>
                    } */}

                </div>
                <div style={{ marginBottom: 50 }}>
                    <h2>Confirm Your Identity</h2>
                    <FormControl style={{ margin: 20 }}>
                        <TextField error={aboutForm.errorMessage.ssn} helperText={aboutForm.errorMessage.ssn} required id="outlined-basic" label="Social Security Number" inputProps={{ maxLength: 9 }} name="ssn" value={aboutForm.ssn} onChange={handlersAbout.handleTexflieds} variant="outlined" />
                        <FormControlLabel style={{ marginTop: 10 }} control={<Checkbox required checked={aboutForm.accep_terms} onChange={handlersAbout.handleAcceptTerms} />} label="I confirm my enrollment for service and have read and agree to the Terms of Service for Energy Texas and Your Rights as a Customer." />
                        <FormHelperText error={aboutForm.accep_terms}>
                            {!aboutForm.accep_terms ? <span style={{ color: "red" }}>Required</span> : ''}
                        </FormHelperText>
                    </FormControl>

                    <span>
                        You should receive a copy of all documents by US mail or email within 2 weeks. You can request an additional copy to be sent to you at any time. You may also print a copy of these documents now by clicking on the links above.
                    </span>

                </div>
            </div>
        </Paper>
    )
}




export default function EnergyTexasSignUp(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [ip, setIP] = useState('');
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }
    const [showStatusModal, setShowStatusModal] = useState({ show: false, success: false, errors: {}, loading:true })
    const [aboutForm, setAboutForm] = useState({
        movingSwitching: 'moving',
        switchingDetails: 'asap',
        autoPay: false,
        promoCode: '',
        billing_same_as_service: true,
        via_email: false,
        rewards: false,
        peak_perks: false,
        accep_terms: false,
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        start_date: '',
        name_on_card: '',
        card_number: '',
        cvv: '',
        exp_year: '',
        exp_month: '',
        promo_code: '',
        ssn: '',
        service_address: '',
        city: '',
        zip: props.zip,
        esiid: '',
        state: 'TX',
        billing_address: '',
        billing_city: '',
        billing_zip: '',
        billing_state: 'TX',
        checkFirstStep: false,
        checkSecondStep: false,
        errorMessage: {},
        showModalSuccess: false,

    })


    const handleNext = () => {

        if (!aboutForm.checkFirstStep) {
            let chek_inputs = {
                service_address: aboutForm.service_address === '',
                esiid: aboutForm.esiid === '',
                city: aboutForm.city === '',
                zip: aboutForm.zip === '',
                state: aboutForm.state === '',
            }
            setAboutForm((aboutForm) => ({ ...aboutForm, errorMessage: chek_inputs }))
            let validation = Object.values(chek_inputs).map(val => { return !val }).every(Boolean)
            if (validation) {
                setAboutForm((aboutForm) => ({ ...aboutForm, checkFirstStep: true }))
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }

    };

    const handleFinish = () => {
        let second_step = false
        let email_regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!second_step && aboutForm.checkFirstStep) {
            let chek_inputs = {
                first_name: aboutForm.first_name === '' ? 'Required' : '',
                last_name: aboutForm.last_name === '' ? 'Required' : '',
                phone_number: aboutForm.phone_number === ''  ? 'Required: must be 10 digits long' : '',
                email_address: (!email_regexp.test(aboutForm.email_address) ) ? 'Invalid email' : '',
                start_date: (aboutForm.movingSwitching === 'moving' || aboutForm.switchingDetails === 'switchDate') && aboutForm.start_date === '' ? 'Required' : '',
                name_on_card: aboutForm.autoPay && aboutForm.name_on_card === '' ? 'Required' : '',
                card_number: aboutForm.autoPay && aboutForm.card_number === '' ? 'Required' : '',
                cvv: aboutForm.autoPay && aboutForm.cvv === '' ? 'Required' : '',
                exp_year: aboutForm.autoPay && aboutForm.exp_year === '' ? 'Required' : '',
                exp_month: aboutForm.autoPay && aboutForm.exp_month === '' ? 'Required' : '',
                ssn: aboutForm.ssn === '' ? 'Required: must be 9 digits long' : '',
                accep_terms: aboutForm.accep_terms === false,
                billing_address: !aboutForm.billing_same_as_service && aboutForm.billing_address === '' ? 'Required' : '',
                billing_city: !aboutForm.billing_same_as_service && aboutForm.billing_city === '' ? 'Required' : '',
                billing_zip: !aboutForm.billing_same_as_service && aboutForm.billing_zip === '' ? 'Required' : '',
                billing_state: !aboutForm.billing_same_as_service && aboutForm.billing_state === '' ? 'Required' : ''
            }

            setAboutForm((aboutForm) => ({ ...aboutForm, errorMessage: chek_inputs }))
            let validation = Object.values(chek_inputs).map(val => { return !val }).every(Boolean)
            if (validation) {
                setAboutForm((aboutForm) => ({ ...aboutForm, checkSecondStep: true }))
                enrolment();
            } else {
                setAboutForm((aboutForm) => ({ ...aboutForm, checkSecondStep: false }))

            }
        }

    }

    const handleBack = () => {
        setAboutForm((aboutForm) => ({ ...aboutForm, checkFirstStep: false, checkSecondStep: false }))
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //about info

    const [showOption, setShowOption] = useState({
        startDate: true,
        switchingDetails: false,
        promoCode: false
    })

    const handlersAbout = {
        handleMovingSwitching: (e) => {
            let option = e.target.value;
            setAboutForm((value) => ({
                ...value,
                movingSwitching: option,
                switchingDetails: 'asap',
                start_date: ''
            }))
            setShowOption((show) => ({
                ...show,
                startDate: option === "moving" ? true : false,
                switchingDetails: option === "moving" ? false : true
            }))
        },
        handleSwitchDetails: (e) => {
            let option = e.target.value
            setAboutForm((value) => ({ ...value, switchingDetails: option, start_date: '' }))
            setShowOption((show) => ({ ...show, startDate: option === "asap" ? false : true }))
        },
        handleAutoPay: (e) => {
            let auto_pay = e.target.checked
            setAboutForm((value) => ({ ...value, autoPay: auto_pay, card_number: '', name_on_card: '', cvv: '', exp_year: '', exp_month: '' }))
        },
        handlePromoCode: (e) => {
            setShowOption((value) => ({ ...value, promoCode: e.target.checked }))
            setAboutForm((value) => ({ ...value, promoCode: '' }))
        },
        handleTexflieds: (e) => {

            let input = e.target.value
            if(e.target.name === "phone_number"){
                input = e.target.value.replace(/[^0-9]/g, "")
            }
            if(e.target.name === "ssn"){
                input = e.target.value.replace(/[^0-9]/g, "")
            }
            
            setAboutForm((value) => ({
                ...value,
                [e.target.name]: input
            }))

            
            
        },
        handleBillingSameAsService: (e) => {
            setAboutForm((value) => ({ ...value, billing_same_as_service: e.target.checked }))
        },
        handleViaEmail: (e) => {
            setAboutForm((value) => ({ ...value, via_email: e.target.checked }))
        },
        handleRwards: (e) => {
            setAboutForm((value) => ({ ...value, rewards: e.target.checked }))
        },
        handlePeakPerks: (e) => {
            setAboutForm((value) => ({ ...value, peak_perks: e.target.checked }))
        },
        handleAcceptTerms: (e) => {
            setAboutForm((value) => ({ ...value, accep_terms: e.target.checked }))
        }
    }
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <StartDate setAboutForm={setAboutForm} aboutForm={aboutForm} handlersAbout={handlersAbout} summary={props.summary} zip={props.zip_code} />
            default:
                return <About aboutForm={aboutForm} showOption={showOption} handlersAbout={handlersAbout} />
        }
    }

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    const enrolment = () => {
        setShowStatusModal((showStatusModal) => ({ ...showStatusModal, show:true, loading:true }))
        let same_as_service = aboutForm.billing_same_as_service;
        //move type
        let moveType = 'move'
        if (aboutForm.movingSwitching === 'switching' && aboutForm.switchingDetails === 'asap') {
            moveType = 'switch'
        }
        if (aboutForm.movingSwitching === 'switching' && aboutForm.switchingDetails === 'switchDate') {
            moveType = 'forward_switch'
        }
        axios.post(`https://powerusapi.herokuapp.com/enrollment/energytexas`, {
            // all mandatory
            plan_id: props.plan_id,
            rate_id: props.rate_id,
            first_name: aboutForm.first_name,
            last_name: aboutForm.last_name,
            phone: aboutForm.phone_number,
            email: aboutForm.email_address,
            ssn: aboutForm.ssn,
            ip: ip,
            billing_address_1: same_as_service ? aboutForm.service_address.split(",")[0] : aboutForm.billing_address.split(",")[0],
            billing_city: same_as_service ? aboutForm.city : aboutForm.billing_city,
            billing_state: same_as_service ? aboutForm.state : aboutForm.billing_state,
            billing_zip: same_as_service ? aboutForm.zip : aboutForm.billing_zip,
            service_address_1: aboutForm.service_address.split(",")[0],
            service_city: aboutForm.city,
            service_state: aboutForm.state,
            service_zip: aboutForm.zip,
            esiid: aboutForm.esiid,
            move_type: moveType,
            start_date: aboutForm.start_date,//only required for "move" and "forward_switch"
            autopay: aboutForm.autoPay,
            card_number: aboutForm.card_number,
            card_name: aboutForm.name_on_card,
            card_exp_month: aboutForm.exp_month,
            card_exp_year: aboutForm.exp_year,
            card_cvv: aboutForm.cvv



        }).then(res => {
            setShowStatusModal((showStatusModal) => ({ ...showStatusModal, show: true, success: res.data.success, errors: res.data.errors, loading:false }))
        }).catch(function (error) {
            setShowStatusModal((showStatusModal) => ({ ...showStatusModal, show: true, success: false, errors: {}, loading:false }))
        })
    }
    useEffect(() => {

    }, [showStatusModal])

    useEffect( () => {
        //passing getData method to the lifecycle method
        getData()
    
      }, [])

    return (
        <Container maxWidth={"xl"}>
            <Grid justifyContent="center" container spacing={2} >
                <Grid style={{ maxWidth: 987 }} item xs={12} md={7} lg={10}>
                    <Box style={{ paddingTop: 25 }} sx={{ width: "100%" }}>
                        {showStatusModal.show ? <StatusModal status={showStatusModal} setShowStatusModal={setShowStatusModal} /> : ''}
                        <Paper style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 20, padding: 20, position: "relative" }} className={"card"} variant="outlined">
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Paper>

                        {/* <div style={{ maxWidth: 987, margin: "auto" }}>
                {props.planCard}
            </div> */}

                        <React.Fragment>

                            {getStepContent(activeStep)}
                            <Paper style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20, paddingLeft: 20, paddingRight: 20, position: "relative" }} className={"card"} variant="outlined">
                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                    <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                        Back
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    {activeStep === steps.length - 1 ?
                                        <Button onClick={handleFinish}>Finish</Button>
                                        :
                                        <Button onClick={handleNext}>Next</Button>}

                                </Box>
                            </Paper>
                        </React.Fragment>

                    </Box>
                </Grid>
                <Grid item xs={2} style={{ minWidth: 270, maxWidth: 400, minHeight: 400 }}>
                    {/* <Paper style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 20, padding: 20, position:"sticky", top:0, zIndex:9, marginTop:25 }} className={["card"]} variant="outlined">

                    </Paper> */}
                    {props.planCard}

                </Grid>
            </Grid>
        </Container >

    );
}