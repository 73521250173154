import { isNumber, hasMaxLength, hasMinLength, isEmail, isEmpty, isNullValue } from "./ValidateFunctions";

function validateFields(initialJson) {

  const json = JSON.parse(JSON.stringify(initialJson));
  let globalError = false, error;
  let value, helperText, type, required;
  const helperSet = {
    "empty": "Required",
    "number": "Write only digts [0-9]",
    "max_length": "Maximun length allowed is ",
    "min_length": "Minimun length allowed is ",
    "email": "Enter correct email"
  }

  for (let key in json) {

    value = json[key].value
    required = json[key].required
    helperText = ""
    error = false;
    if (isNullValue(value) || isEmpty(value)) {
      helperText = required ? helperSet["empty"] : ""
      error = JSON.parse(JSON.stringify(required));
    } else {
      for (let type_index in json[key].type) {
        type = json[key].type[type_index]

        if (type === "number") {
          if (isNumber(value)) continue
          helperText = helperSet[type]
          error = true;
          break;
        }
        else if (type === "max_length") {
          if (hasMaxLength(value, json[key][type])) continue;
          helperText = helperSet[type] + json[key][type].toString()
          error = true;
          break;
        }
        else if (type === "min_length") {
          if (hasMinLength(value, json[key][type])) continue;
          helperText = helperSet[type] + json[key][type].toString()
          error = true;
          break;
        }
        else if (type === "email") {
          if (isEmail(value)) continue;
          helperText = helperSet[type]
          error = true;
          break;
        }
      }
    }
    globalError |= error;

    json[key].error = error
    json[key].helperText = helperText
  }


  return [json, globalError];
};

export { validateFields }