import { Paper, makeStyles } from "@material-ui/core";
import SignUp from "./SignUp";
import Checkbox from '@mui/material/Checkbox';
import Pdflogo from '../../svg/pdf.svg'


const useStyles = makeStyles((theme) => ({
    hideFilter: {
        "@media (max-width: 959px)": {
            display: "none",
        }
    },
    cardContent: {
        "@media (min-width: 1217px)": {
            display: "flex",
        }
    },
    logo: {
        "@media (max-width: 1216px)": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
        },
    },
    description: {
        "@media (max-width: 1218px)": {
            width: "100%"
        },
        "@media (min-width: 1217px)": {
            width: "40%"
        }
    },
    terms: {
        "@media (min-width: 1282px)": {
            display: "flex",
            width:300,
            paddingLeft:0
        },
        "@media (max-width: 1215px)": {
            display: "flex",
            width: "100%"
        },
    },
    btnSignUp: {
        "@media (min-width: 500px)": {
            position: "absolute"
        }
    },
    hideLanguage: {
        "@media (max-width: 529px)": {
            display: "none",
        }
    },
    customSelect: {
        color: "#fff",
        height: 40,
        width: 120,
        background: 'rgb(153, 0, 35)',
        borderRadius: 5,
        borderStyle: "none",
        fontSize: 16
    },
    customInput: {
        color: "#fff",
        height: 38,
        width: 116,
        background: 'rgb(153, 0, 35)',
        borderRadius: 5,
        borderStyle: "none",
        fontSize: 16,
        textAlign: "center"
    },
    showFilterIcon: {
        "@media (min-width: 959px)": {
            display: "none",
        }
    },
    planLogo: {
        "@media (max-width: 1216px)": {
            height: 30

        },
        "@media (min-width: 1217px)": {
            width: 200
        },
    },
    planLogoVertical: {
        height: 40
    },
    containedButton: {
        backgroundColor: "#d40030",
        fontSize: 17,
        textTransform: 'none',
        color: "#fff",
        '&:hover': {
            backgroundColor: '#e35a79',
            color: '#fff',
        }
    }

}))

function VerticalPlan(props) {
    const classes = useStyles();
    const plan = props.plan
    return (
        <Paper style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 20, padding: 20, position: "sticky", top: 0, zIndex: 9, marginTop: 25 }} className={["card"].join(" ")} variant="outlined">
            <div style={{ textAlign: "center", marginBottom: 20 }}>
                <span style={{ color: "#939393", fontSize: 17 }}>Price / kWh</span>
                <p style={{ fontSize: 35, margin: 0, color: "#5b5d60", fontWeight: 500 }}>{parseFloat(props.plan.kwh_1000).toFixed(1)}¢</p>
                <span style={{ color: "#939393", fontSize: 17 }}>@ 1000 Kwh</span>
            </div>
            <div style={{ textAlign: "center", marginBottom: 20 }}>
                <img alt="logo" height={props.plan.image_file === 'champions_texas.png' ? 70 : 40} src={props.plan.image_file}></img>
            </div>
            <div style={{ marginBottom: 20 }}>
                <span style={{ color: "#939393", fontSize: 16, display: "inline-block" }}>Plan Length: <strong>{props.plan.term} Month(s)</strong></span>
                <span style={{ color: "#939393", fontSize: 16, display: "inline-block" }}>Your Utility: <strong>{props.plan.utility_name}</strong></span>
            </div>
            <div style={{ textAlign: "center", marginBottom: 10 }}>
                <span style={{ width: "100%", background: "rgb(245,245,245)", fontSize: 16, display: "inline-block" }}>
                    Your Important Documents
                </span>
                <span style={{ width: "100%", color: "#939393", fontSize: 16, display: "inline-block" }}>
                    <a style={{ padding: "0 4px" }} target={"_blank"} href={props.plan.special_terms_url} rel="noopener noreferrer">
                        Special Terms
                    </a>
                </span>
                <span style={{ width: "100%", color: "#939393", fontSize: 16, display: "inline-block" }}>
                    <a style={{ padding: "0 4px" }} target={"_blank"} href={props.plan.facts_url} rel="noopener noreferrer">
                        Facts
                    </a>
                </span>
                <span style={{ width: "100%", color: "#939393", fontSize: 16, display: "inline-block" }}>
                    <a style={{ padding: "0 4px" }} target={"_blank"} href={props.plan.terms_url} rel="noopener noreferrer">
                        Terms of Service
                    </a>
                </span>
            </div>
            <div style={{ textAlign: "center", marginBottom: 10 }}>
                <span style={{ width: "100%", background: "rgb(245,245,245)", fontSize: 16, display: "inline-block" }}>
                    Please read, print, and save these documents for your reference.
                </span>
            </div>
        </Paper>

    )

}

export default function Plans(props) {
    const classes = useStyles();
    const plan = props.plan
    const vertical = props.vertical
    return (
        <>
            {vertical ? <VerticalPlan plan={plan} /> :
                <Paper style={{ marginBottom: 20, position: "relative" }} className={["card", classes.cardContent].join(" ")} variant="outlined">
                    <div className={classes.logo} style={{ padding: 10 }}>
                        <div style={{ textAlign: "left" }}>
                            {!props.hide &&
                                <Checkbox
                                    value={props.plan.id}
                                    checked={props.compare.includes(props.plan.id.toString())}
                                    onChange={props.handleCheckboxCompare}
                                />
                            }

                        </div>
                        <img alt="logo" className={classes.planLogo} src={props.plan.image_file}></img>
                        <div style={{ color: "#d40030", fontSize: 16, fontWeight: 500, marginRight: 20 }}>PUCT #{props.plan.puct}</div>
                    </div>
                    <div className={classes.description} style={{ padding: 10 }}>
                        <div>
                            <h2 style={{ margin: 0, color: "#d40030", textAlign: "left", fontSize: 24 }}>{props.plan.name}</h2>
                            <div style={{ display: "flex" }}>
                                <div style={{ textAlign: "center", width: "33%" }}>
                                    <p style={{ fontSize: 35, margin: 0, color: "#5b5d60", fontWeight: 500 }}>{parseFloat(props.plan.kwh_500).toFixed(1)}</p>
                                    <span style={{ color: "#939393", fontSize: 17 }}>500 Kwh</span>
                                </div>
                                <div style={{ textAlign: "center", width: "33%" }}>
                                    <p style={{ fontSize: 35, margin: 0, color: "#5b5d60", fontWeight: 500 }}>{parseFloat(props.plan.kwh_1000).toFixed(1)}</p>
                                    <span style={{ color: "#939393", fontSize: 17 }}>1000 Kwh</span>
                                </div>
                                <div style={{ textAlign: "center", width: "33%" }}>
                                    <p style={{ fontSize: 35, margin: 0, color: "#5b5d60", fontWeight: 500 }}>{parseFloat(props.plan.kwh_2000).toFixed(1)}</p>
                                    <span style={{ color: "#939393", fontSize: 17 }}>2000 Kwh</span>
                                </div>
                            </div>
                            <ul style={{ textAlign: "left", columns: 2 }}>
                                <li>{props.plan.term} Month(s)</li>
                                <li>{props.plan.renewable}% Renewable</li>
                            </ul>
                            <div style={{ textAlign: "left" }}>
                                <p style={{ color: "#939393", fontSize: 16, fontWeight: 500 }}>Cancellation Fee: <br /> <span style={{ color: "#d40030" }}>${props.plan.etf} </span></p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.terms} style={{ padding: 15 }}>
                        <div className={classes.terms} style={{ textAlign: "right" }}>
                            <div>
                                <a style={{ padding: "0 4px" }} target={"_blank"} href={props.plan.special_terms_url} rel="noopener noreferrer">
                                    <img src={Pdflogo} alt="special_terms" height={26}/>
                                    <p style={{float:'right', marginTop:0}}>Special Terms</p>
                                </a>
                            </div>
                            <div>
                                <a style={{ padding: "0 4px" }} target={"_blank"} href={props.plan.facts_url} rel="noopener noreferrer">
                                    <img src={Pdflogo} alt="React Logo" height={26}/>
                                    <p style={{float:'right', marginTop:0}}>Facts</p>
                                </a>
                            </div>
                            <div>
                                <a style={{ padding: "0 4px"}} target={"_blank"} href={props.plan.terms_url} rel="noopener noreferrer">
                                    <img src={Pdflogo} alt="React Logo" height={26}/>
                                    <p style={{float:'right', marginTop:0}}>Terms</p>
                                   
                                    
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={classes.btnSignUp} style={{ textAlign: "right", bottom: 0, right: 0, padding: 10 }}>

                        {
                            !props.hide &&
                            <SignUp summary={plan} plan={props.plan.name} zip={props.zip} company={props.plan.company_name} plan_id={props.plan.plan_id || ''} rate_id={props.plan.rate_id || ''} />
                        }

                        <br />
                        <div style={{ paddingTop: 10 }}>
                            <span style={{ color: "#939393", fontSize: 17 }}>Info: {props.plan.contact}</span>
                        </div>
                    </div>
                </Paper>
            }
        </>


    );

}