import React from "react";
import { Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";

import Typography from '@mui/material/Typography';


export default function Disclousures() {
    return (
        <div>
            <div className="pageTitleFont font_32">Disclosures</div>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
            >
                <Grid style={{ maxWidth: 1253 }} item xs={11} md={8}>

                    <Paper className="card" variant="outlined">
                        <div style={{ padding: 50 }}>
                            <Typography variant="body2" align="left">
                                Powerofchoiceusa.com is a marketing tool fully owned and operated by eAgent.Energy. eAgent.Energy located at 5151
                                San Felipe Suite 2200 Houston TX 77056 provides energy procurement and consulting services. eAgent.Energy may be compensated
                                volumetrically by the supplier upon contract execution, superseding any prior verbal agreements to provide
                                procurement services, unless otherwise clearly defined in a separate consulting agreement. All letters of exclusivity, email, and/or verbal agreements to provide
                                procurement services are valid unless canceled in writing with 30 days’ notice.
                            </Typography>

                            <Typography style={{ marginTop: 20 }} variant="body2" align="left">
                                <b>Broker Registration</b> #-BR-190914
                            </Typography>

                            <Typography style={{ marginTop: 20 }} variant="body2" align="left">
                                <b>PUC</b> - Customer Protection
                            </Typography>

                            <Typography style={{ marginTop: 20 }} variant="body2" align="left">
                                <b>P.O. Box</b> 13326 Austin, TX 78711-3326
                            </Typography>

                            <Typography style={{ marginTop: 20 }} variant="body2" align="left">
                                <b>Fax:</b> 1-512-936-7003
                            </Typography>

                            <Typography style={{ marginTop: 20 }} variant="body2" align="left" noWrap>
                                <b>E-mail:</b> <a href="customer@puc.texas.gov" target="_blank">customer@puc.texas.gov</a>
                            </Typography>
                        </div>
                    </Paper>

                </Grid>
            </Grid>
        </div>
    );
}