//Requirements.
import React, { useEffect } from 'react'
import { FormControl, Autocomplete, CircularProgress, Radio, RadioGroup, Divider, Collapse, TextField, Checkbox, FormControlLabel, InputLabel, Select, MenuItem } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

//Styles
import "./../../styles/form.css"

//JSONs
import citiesTX from "./../../json/countriesTX.json"
import axios from 'axios';

const DIRECTION_NOT_FOUND = [{
  service_address: "Not options",
  esiid: "null",
  state: "TX",
  city: "",
}]

var maxDate = new Date;
maxDate.setMonth(maxDate.getMonth() + 8);

var controller = new AbortController();
export default function ChampionPayment(props) {

  const { components } = props;
  const { onChangeIsDifferenteBillingCity, onChangeAddress1, onChangeAddress2, onChangeCity, onChangeESIID, onChangeBillingAddress1, onChangeBillingAddress2, onChangeBillingCity, onChangeBillingStateCode, onChangeBillingZipCode, onChangeIsRenew, onChangeEbill, onChangeRequestedDate, onChangeSignupType } = props.functions;
  const { ZipCode, StateCode, ProviderId } = props.variables;

  const fullwidth = { gridColumn: "1/9" }, firstPart = { gridColumn: "1/5" }, secondPart = { gridColumn: "5/9" }, twoOfThree = { gridColumn: "1/7" }, threeOfTwo = { gridColumn: "7/9" }, firstThird = { gridColumn: "1/4" }, secondThird = { gridColumn: "4/7" }, thirdThird = { gridColumn: "7/9" }, firstQuarter = { gridColumn: "1/3" }, secondQuarter = { gridColumn: "3/5" }, thirdQuarter = { gridColumn: "5/7" }, fourthQuarter = { gridColumn: "7/9" };
  const todayDate = Date.now();

  const [RequestedDate, setRequestedDate] = React.useState(null);
  const [ShowSecondBillingAddress, setShowSecondBillingAddress] = React.useState(false);

  const [IsRenew, setIsRenew] = React.useState(components.IsRenew.value);
  const [BillMethod, setBillMethod] = React.useState(components.Ebill.value);
  const [IsDifferenteBillingCity, setIsDifferenteBillingCity] = React.useState(false)
  const [Address1, setAddress1] = React.useState(components.Address1.value);
  const [Address2, setAddress2] = React.useState(components.Address2.value);
  const [City, setCity] = React.useState(components.City.value);

  const [ESIID, setESIID] = React.useState([]);
  const [ESIIDOptions, setESIIDOptions] = React.useState([]);
  const [OpenESIID, setOpenESIID] = React.useState(false);
  const [Direction, setDirection] = React.useState("");
  const [LoadingESIID, setLoadingESIID] = React.useState(false);
  const [Calling, setCalling] = React.useState(false);
  const [RealESIID, setRealESIID] = React.useState(components.ESIID.value);

  const [openCity, setOpenCity] = React.useState(false);

  const secondBillingAddressContainer = React.useRef(null);
  const BillingCityContainer = React.useRef(null);

  const onChangeDirection = (e) => {
    const dir = e.target.value;
    setDirection(dir);

    if (dir.length > 4 && Calling == false) {
      controller = new AbortController();
      setLoadingESIID(true)
      setCalling(true);

      (async () => {
        try {
          await axios.post("https://powerusapi.herokuapp.com/getESSIDByDirection", {
            "StreetName": dir,
            "StateCode": StateCode,
            "ZipCode": ZipCode,
            "ProviderId": ProviderId
          }, { signal: controller.signal }
          ).then(response => {
            setESIIDOptions(response.data.length ? [...response.data] : DIRECTION_NOT_FOUND);
          })
        } catch (error) {
          setESIID(DIRECTION_NOT_FOUND)
        }
        finally {
          setLoadingESIID(false);
          setCalling(ESIIDOptions.length == 0);
        }
      }
      )();
    }
    else {
      setESIIDOptions(DIRECTION_NOT_FOUND);

      if (dir.length <= 4 && Calling == true) {
        setLoadingESIID(false);
        setCalling(false);
        controller.abort();
      }
    }
  }

  useEffect(() => {
  }, [Direction])

  const changeRequestedDate = (date) => {
    onChangeRequestedDate(date)
    setRequestedDate(date)
  }

  const fillBillingAddress = (event) => {
    setShowSecondBillingAddress((event.target.value).length > 4)
    onChangeBillingAddress1(event)
  }

  const changeBillMethod = (event) => {
    setBillMethod(event.target.value)
    onChangeEbill(event)
  }

  const changeIsRenew = (event) => {
    setIsRenew(event.target.checked)
    onChangeIsRenew(event.target.checked ? 1 : 0);
  }

  const changeESIID = (_, valueJson) => {
    setESIID(valueJson);
    onChangeESIID(valueJson.esiid)
    setRealESIID(valueJson.esiid);

    onChangeCity(valueJson.city)
    setCity(valueJson.city);

    onChangeAddress1(valueJson.service_address)
  }

  React.useEffect(() => {
    if (!OpenESIID) {
      setESIIDOptions([]);
      setDirection("");
    }
  }, [OpenESIID]);

  return (

    <FormControl id="grid_ChampionsForm">
      <h3 id="title" style={fullwidth}>{"Where's The Power Goin'?"}</h3>
      <Divider id='m-divider' style={fullwidth} />


      {/* <TextField
      style={twoOfThree}
      defaultValue={Address1}
      required={components.Address1.required}
      error={components.Address1.error}
      onChange={changeAddress1}
      helperText={components.Address1.helperText}
      label={components.Address1.label}
    />
    <Autocomplete
      value={City}
      style={threeOfTwo}
      onChange={changeCity}

      open={openCity}
      onOpen={() => {
        setOpenCity(true);
      }}
      onClose={() => {
        setOpenCity(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option}
      options={citiesTX}
      renderInput={(params) => (
        <TextField
          {...params}
          error={components.City.error}
          required={components.City.required}
          helperText={components.City.helperText}
          label={components.City.label}
        />
      )}
    /> */}

      {/* <TextField
        disabled={props.disabled}
        style={fullwidth}
        defaultValue={components.Address2.value}
        required={components.Address2.required}
        error={components.Address2.error}
        onChange={changeAddress2}
        helperText={components.Address2.helperText}
        label={components.Address2.label}
      /> */}


      <Autocomplete
        onChange={changeESIID}
        style={fullwidth}
        open={OpenESIID}
        value={ESIID}
        onOpen={() => {
          setOpenESIID(true);
        }}
        onClose={() => {
          setOpenESIID(false);
        }}
        getOptionDisabled={(option) =>
          option.service_address !== null && option.esiid === "null"
        }

        isOptionEqualToValue={(option, value) => option.service_address === value.service_address}
        getOptionLabel={(option) => option.service_address ? option.service_address : ""}
        options={ESIIDOptions}
        loading={LoadingESIID}
        renderInput={(params) => (
          <TextField
            {...params}
            error={components.ESIID.error}
            required={components.ESIID.required}
            helperText={components.ESIID.helperText}
            label={components.ESIID.label}
            onChange={onChangeDirection}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {LoadingESIID ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <TextField
        style={{ gridColumn: "1/7" }}
        defaultValue={City}
        disabled
        value={City}
        label={components.City.label}
      />
      <TextField
        disabled={true}
        style={{ gridColumn: "7" }}
        defaultValue={StateCode}
        label={"State"}
      />
      <TextField
        disabled={true}
        style={{ gridColumn: "8" }}
        defaultValue={ZipCode}
        label={"Zip Code"}
      />
      <TextField
        disabled={true}
        style={fullwidth}
        value={RealESIID}
        label={"ESIID"}
      />

    </FormControl>
  )

}
