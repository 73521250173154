import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Paper } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, FormControl } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { lista_planes, uniqueTDU, TDU_AREAS } from "./Power/Power";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    containedButton: {
        backgroundColor: "#e35a79",
        fontSize: 17,
        color: "#fff",
        '&:hover': {
            backgroundColor: '#E53159',
            color: '#fff',
        }
    },
    outlinedButton: {
        fontSize: 17,
        '&:hover': {
            backgroundColor: '#7582a4',
            color: '#fff',
        }

    }
}));

function FormDialog(props) {
    const navigation = useNavigate();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const { tduAvailable, tduArea, handleTduArea, view_results, zip, setLoadAnimation, loadAnimation, setTDUArea, setCheckZipCode, errorPlans } = props;
    const handleClickOpen = () => {
        if(zip.length==5){
            view_results(zip)
            setOpen(true);
            setCheckZipCode(false)
        }else{
            setCheckZipCode(true)
        }
        
    };

    const handleClose = () => {
        setLoadAnimation(true)
        setTDUArea("")
        setOpen(false);
    };

    return (
        <div>
            <Button fullWidth className={classes.containedButton} variant="outlined" onClick={handleClickOpen}>
                View Results
            </Button>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Please select your utility</DialogTitle>
                <DialogContent style={{ textAlign: "center", padding:20 }}>
                    {loadAnimation ?
                        <div >
                            <CircularProgress />
                        </div>

                        :
                        tduAvailable.length!=0?
                        <FormControl fullWidth variant="standard">
                            <TextField
                                select // tell TextField to render select
                                label="TDU Area"
                                value={tduArea}
                                variant="outlined"
                                onChange={handleTduArea}
                                id="demo-simple-select-standard"
                            >
                                {tduAvailable.map((tdu, key) => { return (<MenuItem key={key} value={tdu.value}>{tdu.label}</MenuItem>) })}
                            </TextField>
                        </FormControl>: errorPlans?"A problem has occurred, please try again later.":<div>There are no results for this zip code <strong>{zip}</strong></div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={tduArea===""} onClick={() => { navigation(`/power?zip=${zip}`, { state: { zip: zip, tdu_area:tduArea }, search: { zip: zip } }) }} >Search</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



export default function Home() {
    const classes = useStyles();
    const navigation = useNavigate();

    const [zipCode, setZipCode] = useState("")
    const [tduArea, setTDUArea] = useState("")
    const [tduAvailable, setTduAvailable] = useState([])
    const [loadAnimation, setLoadAnimation] = useState(true)
    const [checkZipCode, setCheckZipCode] = useState(false)
    const [errorPlans, setErrorPlans] = useState(false)

    const view_results = (zip) => {
        setErrorPlans(false)
        lista_planes(zip).then(planes => {
            let tdu_list = uniqueTDU(planes).map(function (tdu) {
                return { label: TDU_AREAS[tdu], value: tdu }
            })
            setTduAvailable(tdu_list)
            setLoadAnimation(false)
        }).catch(function(error){
            setLoadAnimation(false)
            setErrorPlans(true)
        })
    }

    const handleTduArea = (e) => {
        setTDUArea(e.target.value)
    }

    const handleZipCode = (e) => {
        let input = e.target.value.replace(/[^0-9]/g, "")
        setZipCode(input)
    }
    return (
        <div>
            <div className="pageTitleFont font_32">Enter Your Zipcode</div>
            <Grid
                container
                alignItems="center"
                justifyContent="center"


            >
                <Grid style={{ maxWidth: 389 }} item xs={9} md={4} lg={3}>
                    <>
                        <Paper className="card" variant="outlined">
                            <div style={{ padding: 30 }}>
                                <Typography style={{ padding: 5 }} variant="h6">
                                    Welcome!
                                </Typography>

                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField InputProps={{maxLength:5}} onChange={handleZipCode} error={checkZipCode} helperText={checkZipCode && "Required zip code: must be 5 digits"} inputProps={{ maxLength: 5 }} fullWidth id="outlined-basic" label="Please enter your zipcode" variant="outlined" value={zipCode} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormDialog
                                            view_results={view_results}
                                            zip={zipCode}
                                            tduAvailable={tduAvailable}
                                            tduArea={tduArea}
                                            handleTduArea={handleTduArea}
                                            setLoadAnimation={setLoadAnimation}
                                            loadAnimation={loadAnimation}
                                            setTDUArea={setTDUArea}
                                            setCheckZipCode = {setCheckZipCode}
                                            errorPlans = {errorPlans}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button disabled onClick={() => { navigation(`/user_guide`) }} className={classes.outlinedButton} fullWidth variant="outlined" disableElevation>NARROW YOUR SEARCH</Button>
                                    </Grid>
                                </Grid>

                            </div>
                        </Paper>
                    </>
                </Grid>
            </Grid>
        </div>
    );
}