import { forwardRef, useState } from 'react';
import { Button } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import EnergyTexasSignUp from '../EnergyTexasSignUp';
import ChampionsSignUp from '../champion/ChampionsSignUp';
import { makeStyles} from '@material-ui/core';
import Plans from './Plans';
import Fondo from '../../static/bg_1.jpg'
import TexpoSignUp from '../texpo/TexpoSignUp';

const useStyles = makeStyles((theme) => ({
    containedButton: {
        backgroundColor: "#d40030",
        fontSize: 17,
        textTransform: 'none',
        color: "#fff",
        '&:hover': {
            backgroundColor: '#e35a79',
            color: '#fff',
        }
    }
}));

function getCompanyForm(name, plan_id, rate_id, zip, summary) {
    switch (name) {
        case "championsenergy":
            return <ChampionsSignUp planCard={<Plans vertical={true} key={1} id={1} plan={summary} zip={zip} compare={[]} handleCheckboxCompare={''} hide={true}/>} plan_id={plan_id} rateId={rate_id} zip_code={zip} summary={summary} />
        case "energytexas":
            return <EnergyTexasSignUp planCard={<Plans vertical={true} key={1} id={1} plan={summary} zip={zip} compare={[]} handleCheckboxCompare={''} hide={true}/>} plan_id={plan_id} rate_id={rate_id} zip_code={zip} summary={summary}/>
        case "texpoenergy":
            return <TexpoSignUp planCard={<Plans vertical={true} key={1} id={1} plan={summary} zip={zip} compare={[]} handleCheckboxCompare={''} hide={true}/>} plan_id={plan_id} rate_id={rate_id} zip_code={zip} summary={summary}/>
        default:
            break

        // case 2:
        //     return <Payment/>
        // case 3:
        //     return <Review/>

    }
}


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignUp(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                onClick={handleClickOpen}
                className={classes.containedButton}
                fullWidth variant="contained"
                disableElevation>
                Choose this
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    style:{
                        backgroundImage: `url("${Fondo}")`
                    }
                }}
            >
                <AppBar style={{ background: '#d40030' }} sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {props.plan}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className='stepper_container'>
                    {getCompanyForm(props.company, props.plan_id, props.rate_id, props.zip, props.summary)}
                </div>
            </Dialog>
        </div>
    );
}