import { Paper } from "@mui/material";
import Plans from "./Plans";

export default function Results(props) {
    const { filteredPlans, handlers, zip } = props
    switch (filteredPlans.length) {
        case 0:
            return (
                <Paper style={{ padding: 20 }} className="card" variant="outlined">
                    <h1>No Plans Available</h1>
                </Paper>)
        default:
            return (
                filteredPlans.map((plan, key) =>
                    <Plans key={key} id={key} plan={plan} zip={zip} compare={props.compare} handleCheckboxCompare={handlers.handleCompare} />
                ))
    }

}