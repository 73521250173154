import {Fragment, forwardRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Filters } from './Power/Power';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterDialog(props) {
    const [open, setOpen] = useState(false);
    const { filterOptions, drawfilterOptions } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <FilterAltIcon style={{ color: "#fff" }} />
            </IconButton>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar style={{ background: '#211837' }} sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Select Filters
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>

                    {filterOptions.map(({ label, multiple, handleChange, options, value }, key) =>
                       <Fragment key={key}>
                           <ListItem>
                            <Filters
                                label={label}
                                multiple={multiple}
                                options={options}
                                handlers={handleChange}
                                selected={value}
                                drawfilterOptions={drawfilterOptions}
                            />
                        </ListItem>
                            <Divider />
                       </Fragment> 
                    )}




                    {/* <ListItem>
                        <FormControl fullWidth>
                            <FormLabel align="left" id="demo-radio-buttons-group-label">Contract length</FormLabel>
                            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                                <Slider
                                    getAriaLabel={() => 'Contract'}
                                    value={props.selected.term}
                                    onChange={props.handlers.handleContractLength}
                                    valueLabelDisplay="auto"
                                    marks={props.marks}
                                    min={1}
                                    max={60}

                                />
                            </div>
                        </FormControl>

                    </ListItem>
                    <Divider /> */}

                </List>
            </Dialog>
        </div>
    );
}
