//Requirements.
import React, { useEffect } from 'react'
import { FormControl, Autocomplete, CircularProgress, Radio, RadioGroup, Divider, Collapse, TextField, Checkbox, FormControlLabel, InputLabel, Select, MenuItem } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

//Styles
import "./../../styles/form.css"

//JSONs
import axios from 'axios';

const DIRECTION_NOT_FOUND = [{
  service_address: "Not options",
  CompetitiveResellerKey: "",
  esiid: "null",
  state: "TX",
  city: "",
  PlusFourOfZip: ""
}]

var maxDate = new Date;
maxDate.setMonth(maxDate.getMonth() + 8);

var controller = new AbortController();
export default function TexpoPayment(props) {

  const { components } = props;
  const {onChangeSLZipPlusFour, onChangeNeedsNewAccount, onChangeSiteCode, onChangeCurrentlyHasService, onChangePromotionCode, onChangeEnrollmentDate, onChangeServiceStartDate, onChangeFirstName, onChangeLastName, onChangeEmail, onChangePhoneNumber, onChangeBAAddressLineOne, onChangeBACity, onChangeBAState, onChangeBAZip, onChangeSLAddressLineOne, onChangeSLCity, onChangeSLState, onChangeSLZip, onChangeSSN, onChangeDateOfBirth, onChangeLanguage, onChangeAccountType, onChangePremiseType} = props.functions;
  const {CompetitiveResellerKey, ZipCode, StateCode, tdu_area } = props.variables;

  const fullwidth = { gridColumn: "1/9" }, firstPart = { gridColumn: "1/5" }, secondPart = { gridColumn: "5/9" }, twoOfThree = { gridColumn: "1/7" }, threeOfTwo = { gridColumn: "7/9" }, firstThird = { gridColumn: "1/4" }, secondThird = { gridColumn: "4/7" }, thirdThird = { gridColumn: "7/9" }, firstQuarter = { gridColumn: "1/3" }, secondQuarter = { gridColumn: "3/5" }, thirdQuarter = { gridColumn: "5/7" }, fourthQuarter = { gridColumn: "7/9" };
  const todayDate = Date.now();


  const [City, setCity] = React.useState(components.SLCity.value);

  const [ESIID, setESIID] = React.useState([]);
  const [ESIIDOptions, setESIIDOptions] = React.useState([]);
  const [OpenESIID, setOpenESIID] = React.useState(false);
  const [Direction, setDirection] = React.useState("");
  const [LoadingESIID, setLoadingESIID] = React.useState(false);
  const [Calling, setCalling] = React.useState(false);
  const [RealESIID, setRealESIID] = React.useState(components.SiteCode.value);

  const [openCity, setOpenCity] = React.useState(false);

  const secondBillingAddressContainer = React.useRef(null);
  const BillingCityContainer = React.useRef(null);

  const onChangeDirection = (e) => {
    const dir = e.target.value; 
    setDirection(dir);

    if (dir.length > 4 && Calling == false) {
      controller = new AbortController();
      setLoadingESIID(true)
      setCalling(true);

      (async () => {
        try {
          await axios.post("https://powerusapi.herokuapp.com/getESSIDByDirection/Texpo", {
            "AddressLineOne": dir,
            "CompetitiveResellerKey": CompetitiveResellerKey,
            "State": StateCode,
            "Zip": ZipCode,
          }, { signal: controller.signal }
          ).then(response => {
            setESIIDOptions(response.data.length ? [...response.data] : DIRECTION_NOT_FOUND);
          })
        } catch (error) {
          setESIID(DIRECTION_NOT_FOUND)
        }
        finally {
          setLoadingESIID(false);
          setCalling(ESIIDOptions.length == 0);

        }
      }
      )();
    }
    else {
      setESIIDOptions(DIRECTION_NOT_FOUND);

      if (dir.length <= 4 && Calling == true) {
        setLoadingESIID(false);
        setCalling(false);
        controller.abort();
      }
    }
  }

  useEffect(() => {
  }, [Direction])

  const changeESIID = (_, valueJson) => {
    setESIID(valueJson);
    onChangeSiteCode(valueJson.esiid)
    setRealESIID(valueJson.esiid);

    onChangeSLCity(valueJson.city)
    onChangeSLZipPlusFour(valueJson.PlusFourOfZip)
    setCity(valueJson.city);

    onChangeSLAddressLineOne(valueJson.service_address)
  }

  React.useEffect(() => {
    if (!OpenESIID) {
      setESIIDOptions([]);
      setDirection("");
    }
  }, [OpenESIID]);

  return (

    <FormControl id="grid_ChampionsForm">
      <h3 id="title" style={fullwidth}>{"Where's The Power Goin'?"}</h3>
      <Divider id='m-divider' style={fullwidth} />

      <Autocomplete
        onChange={changeESIID}
        style={fullwidth}
        open={OpenESIID}
        value={ESIID}
        onOpen={() => {
          setOpenESIID(true);
        }}
        onClose={() => {
          setOpenESIID(false);
        }}
        getOptionDisabled={(option) =>
          option.service_address !== null && option.esiid === "null"
        }

        isOptionEqualToValue={(option, value) => option.service_address === value.service_address}
        getOptionLabel={(option) => option.service_address ? option.service_address : ""}
        options={ESIIDOptions}
        loading={LoadingESIID}
        renderInput={(params) => (
          <TextField
            {...params}
            error={components.SiteCode.error}
            required={components.SiteCode.required}
            helperText={components.SiteCode.helperText}
            label={components.SiteCode.label}
            onChange={onChangeDirection}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {LoadingESIID ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <TextField
        style={{ gridColumn: "1/7" }}
        defaultValue={City}
        disabled
        value={City}
        label={components.SLCity.label}
      />
      <TextField
        disabled={true}
        style={{ gridColumn: "7" }}
        defaultValue={StateCode}
        label={"State"}
      />
      <TextField
        disabled={true}
        style={{ gridColumn: "8" }}
        defaultValue={ZipCode}
        label={"Zip Code"}
      />
      <TextField
        disabled={true}
        style={fullwidth}
        value={RealESIID}
        label={"ESIID"}
      />

    </FormControl>
  )

}
