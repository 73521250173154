//Dependences
import React, { useEffect, useState } from "react";
import { Typography, Button, StepLabel, Collapse, AlertTitle, Alert, Box, Stepper, Step } from '@mui/material';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper, Container, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CircularProgress } from "@mui/material";


import { AboutTexpo } from "./TexpoAbout"
import TexpoPayment from "./TexpoPayment";


//Auxiliar functions
import { validateFields } from "../../functions/ValidateForm";
import { parseJson2Server } from "./../../functions/TransformJson";

//Json
import defaultValues from "./json/defaultValueFields.json"
const components = JSON.parse(JSON.stringify(require("./json/texpoValues.json")))

const steps = ["SERVICE ADDRESS", "PERSONAL INFO"];
const TEXPO_ENERGY = "texpoenergy";

function getStepContent(step, childrenProps) {

  switch (step) {
    case 0:
      return <TexpoPayment components={childrenProps.components} functions={childrenProps.functions} variables={childrenProps.variables} />
    case 1:
      return <AboutTexpo components={childrenProps.components} functions={childrenProps.functions} />
    default:
      break;
  }
}

function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('-')
}

function convertServiceStartDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/')
}


export default function TexpoSignUp(props) {
  //#region setComponents.

  const [NeedsNewAccount, setNeedsNewAccount] = useState(components.NeedsNewAccount)
  const [CurrentlyHasService, setCurrentlyHasService] = useState(components.CurrentlyHasService)
  const [PromotionCode, setPromotionCode] = useState(components.PromotionCode)
  const [EnrollmentDate, setEnrollmentDate] = useState(components.EnrollmentDate)
  const [ServiceStartDate, setServiceStartDate] = useState(components.ServiceStartDate)
  const [FirstName, setFirstName] = useState(components.FirstName)
  const [LastName, setLastName] = useState(components.LastName)
  const [Email, setEmail] = useState(components.Email)
  const [PhoneNumber, setPhoneNumber] = useState(components.PhoneNumber)
  const [BAAddressLineOne, setBAAddressLineOne] = useState(components.BAAddressLineOne)
  const [BACity, setBACity] = useState(components.BACity)
  const [BAState, setBAState] = useState(components.BAState)
  const [BAZip, setBAZip] = useState(components.BAZip)
  const [SLAddressLineOne, setSLAddressLineOne] = useState(components.SLAddressLineOne)
  const [SLCity, setSLCity] = useState(components.SLCity)
  const [SLState, setSLState] = useState(components.SLState)
  const [SSN, setSSN] = useState(components.SSN)
  const [DateOfBirth, setDateOfBirth] = useState(components.DateOfBirth)
  const [Language, setLanguage] = useState(components.Language)
  const [AccountType, setAccountType] = useState(components.AccountType)
  const [PremiseType, setPremiseType] = useState(components.PremiseType)
  const [SiteCode, setSiteCode] = useState(components.SiteCode)
  const [SLZipPlusFour, setSLZipPlusFour] = useState(components.SLZipPlusFour)
  const [ButtonDisabled, setButtonDisabled] = useState(false)
  const [ResponseMessage, setResponseMessage] = useState("All step was done")
  const [ShowEndAlert, setShowEndAlert] = useState(false);
  const [Success, setSuccess] = useState(false);
  //#endregion

  //#region onChange
  const onChangeSiteCode = (value) => {
    const SiteCodeCopy = SiteCode;
    SiteCodeCopy.value = value;
    setSiteCode(SiteCodeCopy)
  }
  const onChangeSLZipPlusFour = (value) => {
    const SLZipPlusFourCopy = SLZipPlusFour;
    SLZipPlusFourCopy.value = value;
    setSLZipPlusFour(SLZipPlusFourCopy)
  }
  const onChangeNeedsNewAccount = (event) => {
    const NeedsNewAccountCopy = NeedsNewAccount;
    NeedsNewAccountCopy.value = event.target.value;
    setNeedsNewAccount(NeedsNewAccountCopy)
  }
  const onChangeCurrentlyHasService = (checked) => {
    const CurrentlyHasServiceCopy = CurrentlyHasService;
    CurrentlyHasServiceCopy.value = checked;
    setCurrentlyHasService(CurrentlyHasServiceCopy)
  }
  const onChangePromotionCode = (event) => {
    const PromotionCodeCopy = PromotionCode;
    PromotionCodeCopy.value = event.target.value;
    setPromotionCode(PromotionCodeCopy)
  }
  const onChangeServiceStartDate = (date) => {
    const ServiceStartDateCopy = ServiceStartDate;
    ServiceStartDateCopy.value = date;
    setServiceStartDate(ServiceStartDateCopy)
  }
  const onChangeFirstName = (event) => {
    const FirstNameCopy = FirstName;
    FirstNameCopy.value = event.target.value;
    setFirstName(FirstNameCopy)
  }
  const onChangeLastName = (event) => {
    const LastNameCopy = LastName;
    LastNameCopy.value = event.target.value;
    setLastName(LastNameCopy)
  }
  const onChangeEmail = (event) => {
    const EmailCopy = Email;
    EmailCopy.value = event.target.value;
    setEmail(EmailCopy)
  }
  const onChangePhoneNumber = (event) => {
    const PhoneNumberCopy = PhoneNumber;
    PhoneNumberCopy.value = event.target.value;
    setPhoneNumber(PhoneNumberCopy)
  }
  const onChangeBAAddressLineOne = (event) => {
    const BAAddressLineOneCopy = BAAddressLineOne;
    BAAddressLineOneCopy.value = event.target.value;
    setBAAddressLineOne(BAAddressLineOneCopy)
  }
  const onChangeBACity = (event) => {
    const BACityCopy = BACity;
    BACityCopy.value = event.target.value;
    setBACity(BACityCopy)
  }
  const onChangeBAState = (event) => {
    const BAStateCopy = BAState;
    BAStateCopy.value = event.target.value;
    setBAState(BAStateCopy)
  }
  const onChangeBAZip = (event) => {
    const BAZipCopy = BAZip;
    BAZipCopy.value = event.target.value;
    setBAZip(BAZipCopy)
  }
  const onChangeSLAddressLineOne = (address) => {
    const SLAddressLineOneCopy = SLAddressLineOne;
    SLAddressLineOneCopy.value = address;
    setSLAddressLineOne(SLAddressLineOneCopy)
  }
  const onChangeSLCity = (city) => {
    const SLCityCopy = SLCity;
    SLCityCopy.value = city;
    setSLCity(SLCityCopy)
  }
  const onChangeSSN = (event) => {
    const SSNCopy = SSN;
    SSNCopy.value = event.target.value;
    setSSN(SSNCopy)
  }
  const onChangeDateOfBirth = (date) => {
    const DateOfBirthCopy = DateOfBirth;
    DateOfBirthCopy.value = date;
    setDateOfBirth(DateOfBirthCopy)
  }
  const onChangeLanguage = (event) => {
    const LanguageCopy = Language;
    LanguageCopy.value = event.target.value;
    setLanguage(LanguageCopy)
  }
  const onChangeAccountType = (event) => {
    const AccountTypeCopy = AccountType;
    AccountTypeCopy.value = event.target.value;
    setAccountType(AccountTypeCopy)
  }
  const onChangePremiseType = (event) => {
    const PremiseTypeCopy = PremiseType;
    PremiseTypeCopy.value = event.target.value;
    setPremiseType(PremiseTypeCopy)
  }
  const onChangeIsDifferenteBillingCity = (checked) => {
    const BillingAddress1Copy = BAAddressLineOne;
    const BillingCityCopy = BACity;
    const BillingStateCodeCopy = BAState;
    const BillingZipCodeCopy = BAZip;
    BillingAddress1Copy.required = BillingStateCodeCopy.required = BillingCityCopy.required = BillingZipCodeCopy.required = checked

    setBAAddressLineOne(BillingAddress1Copy);
    setBACity(BillingCityCopy)
    setBAState(BillingStateCodeCopy);
    setBAZip(BillingZipCodeCopy);
  }

  //#endregion


  //#region functions
  const functionsChildrenProps = {
    onChangeSLZipPlusFour: onChangeSLZipPlusFour,
    onChangeNeedsNewAccount: onChangeNeedsNewAccount,
    onChangeCurrentlyHasService: onChangeCurrentlyHasService,
    onChangePromotionCode: onChangePromotionCode,
    onChangeServiceStartDate: onChangeServiceStartDate,
    onChangeFirstName: onChangeFirstName,
    onChangeLastName: onChangeLastName,
    onChangeEmail: onChangeEmail,
    onChangePhoneNumber: onChangePhoneNumber,
    onChangeBAAddressLineOne: onChangeBAAddressLineOne,
    onChangeBACity: onChangeBACity,
    onChangeBAState: onChangeBAState,
    onChangeBAZip: onChangeBAZip,
    onChangeSLAddressLineOne: onChangeSLAddressLineOne,
    onChangeSLCity: onChangeSLCity,
    onChangeSSN: onChangeSSN,
    onChangeDateOfBirth: onChangeDateOfBirth,
    onChangeLanguage: onChangeLanguage,
    onChangeAccountType: onChangeAccountType,
    onChangePremiseType: onChangePremiseType,
    onChangeSiteCode: onChangeSiteCode,
    onChangeIsDifferenteBillingCity: onChangeIsDifferenteBillingCity
  }
  //#endregion

  //#region variables

  const aboutValues = {
    CurrentlyHasService: CurrentlyHasService,
    ServiceStartDate: ServiceStartDate,
    FirstName: FirstName,
    LastName: LastName,
    Email: Email,
    PhoneNumber: PhoneNumber,
    BAAddressLineOne: BAAddressLineOne,
    BACity: BACity,
    SSN: SSN,
    DateOfBirth: DateOfBirth,
    Language: Language,
    BAState: BAState,
    BAZip: BAZip
  }

  const serviceValues = {
    SLAddressLineOne: SLAddressLineOne,
    SLCity: SLCity,
    SiteCode: SiteCode,
    SLZipPlusFour: SLZipPlusFour
  }

  //#endregion variables

  const { zip_code } = props;
  const { utility_name: tdu_area } = props.summary;
  const { CompetitiveResellerKey, ServiceProviderKey, rate_id, CommodityType } = props.summary
  const [childrenProps, setChildrenProps] = useState(JSON.parse(JSON.stringify(require("./json/texpoValues.json"))))

  async function enviarAAxios(childrenProps) {

    const auxiliarJson = parseJson2Server(childrenProps)

    let DateOfBirth = new Date(auxiliarJson.DateOfBirth)
    DateOfBirth = convertDate(DateOfBirth)
    const ServiceStartDate = new Date(auxiliarJson.ServiceStartDate);
    const dateNow = new Date(Date.now());
    const request = {
      "CurrentlyHasService": auxiliarJson.CurrentlyHasService,
      "EnrollmentDate": dateNow,
      "ServiceStartDate": convertServiceStartDate(ServiceStartDate),
      "FirstName": auxiliarJson.FirstName,
      "LastName": auxiliarJson.LastName,
      "Email": auxiliarJson.Email,
      "PrimaryPhone": auxiliarJson.PhoneNumber,
      "BAAddressLineOne": auxiliarJson.BAAddressLineOne != "" ? auxiliarJson.BAAddressLineOne : auxiliarJson.SLAddressLineOne.split(",")[0],
      "BACity": auxiliarJson.BACity != "" ? auxiliarJson.BACity : auxiliarJson.SLCity,
      "BAState": auxiliarJson.BAState != "" ? auxiliarJson.BAState : auxiliarJson.SLState,
      "BAZip": auxiliarJson.BAZip != "" ? auxiliarJson.BAZip : zip_code,
      "SLAddressLineOne": auxiliarJson.SLAddressLineOne.split(",")[0],
      "SLCity": auxiliarJson.SLCity,
      "SLState": auxiliarJson.SLState,
      "SLZip": zip_code,
      "SSN": auxiliarJson.SSN,
      "DateOfBirth": DateOfBirth,
      "Language": auxiliarJson.Language,
      "SiteCode": auxiliarJson.SiteCode,
      "CompetitiveResellerKey": CompetitiveResellerKey,
      "ServiceProviderKey": ServiceProviderKey,
      "RatePlanKey": rate_id,
      "CommodityType": CommodityType,
      "SLZipPlusFour": auxiliarJson.SLZipPlusFour
    }
    let data;
    try {
      await axios.post(`https://powerusapi.herokuapp.com/enrollment/${TEXPO_ENERGY}`, request).then(res => {
        data = res.data;
      })
    }
    catch (error) {
      data = { ExceptionMessage: "Server error. Sorry, try it later." }
    }
    if (data === null) data = { ExceptionMessage: "Server error. Sorry, try it later." }

    return data;
  }

  useEffect(() => (
    setChildrenProps(JSON.parse(JSON.stringify(require("./json/texpoValues.json"))))
  ), [])

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const [activeStep, setActiveStep] = React.useState(0);

  const [ShowAlert, setShowErrorAlert] = useState(false);
  const [AlertLabel, setAlertLabel] = useState("");
  const [ButtonLoading, setButtonLoading] = useState(false);

  const handleOpen = (text) => {
    if (text) setAlertLabel(text);
    setShowErrorAlert(true)


    setTimeout(() => {
      setShowErrorAlert(false);
    }, 5000)

  }

  const handleOpenEnd = (text, error = true) => {
    setShowEndAlert(true);
    setSuccess(!error);
    setAlertLabel(text)
  }

  const handleNext = async () => {
    setButtonLoading(true);
    let checkValues = {};
    switch (activeStep) {
      case 0:
        checkValues = serviceValues
        break;
      case 1:
        checkValues = aboutValues
        break;
    }

    const [json, errorExist] = validateFields(checkValues)
    const childrenPropsAux = { ...childrenProps, ...json }
    setChildrenProps(childrenPropsAux)

    if (!errorExist) {
      if (activeStep === steps.length - 1) {
        setButtonLoading(true)

        const response = await enviarAAxios(childrenPropsAux);
        if (!response["ExceptionMessage"] && !response["HasErrors"]) {
          setResponseMessage(response["Message"]);
          handleOpenEnd("", false);
        }
        else {
          setButtonLoading(false);
          return handleOpenEnd(response["HasErrors"] ? `${response["ErrorMsg"]}\n Please call us at 713 358 5479 for further assistance
          ` : "\n Please call us at 713 358 5479 for further assistance")
        }
      } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else handleOpen("Missing fields");

    setButtonLoading(false);
  }


  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const navigation = useNavigate();

  return (
    <>
      <Collapse in={ShowAlert}>
        <Alert style={{ position: "fixed", top: "0", width: "100%", zIndex: "1000000" }} severity="error">
          <AlertTitle>Error</AlertTitle>
          <strong>{AlertLabel}</strong>
        </Alert>
      </Collapse>
      <Dialog
        open={ShowEndAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowEndAlert(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle >{"Enrollment"}</DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'} id="alert-dialog-slide-description">
            {ButtonLoading ? <div style={{ textAlign: 'center' }}><CircularProgress style={{ marginLeft: 100, marginRight: 100 }} /></div> :
              Success ?
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  <td dangerouslySetInnerHTML={{ __html: ResponseMessage }} />
                  <h4>If you need further assistance, please call us at 713 358 5479</h4>
                </Alert>
                :
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {AlertLabel}
                </Alert>


            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {Success ?
            <Button onClick={() => { navigation(`/`) }}>Close</Button>
            :
            <Button onClick={() => setShowEndAlert(false)}>Close</Button>
          }

        </DialogActions>
      </Dialog>
      <Container maxWidth={"xl"}>
        <Grid justifyContent="center" container spacing={2} >
          <Grid style={{ maxWidth: 987 }} item xs={12} md={7} lg={10}>
            <Box style={{ paddingTop: 25 }} sx={{ width: "100%" }}>

              <Paper style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 20, padding: 20, position: "relative" }} className={"card"} variant="outlined">
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Paper>
              {/* <Button onClick={enviarAAxios} >ENVIAR A AXIOS AAAGH</Button> */}
              <Paper style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20, paddingLeft: 20, paddingRight: 20, position: "relative" }} className={"card"} variant="outlined">
                {
                  getStepContent(activeStep, { functions: functionsChildrenProps, components: childrenProps, variables: { CompetitiveResellerKey: CompetitiveResellerKey, ZipCode: zip_code, tdu_area: tdu_area, StateCode: defaultValues["SLState"] } })
                }
              </Paper>
              {activeStep === steps.length ? null :
                <Paper style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10, paddingRight: 20, position: "relative" }} className={"card"} variant="outlined">
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <LoadingButton loading={ButtonLoading} disabled={ButtonDisabled} onClick={handleNext}>{activeStep === steps.length - 1 ? "Finish" : "Next"}</LoadingButton>
                  </Box>
                </Paper>
              }
            </Box >
          </Grid>

          <Grid item xs={2} style={{ minWidth: 270, maxWidth: 400, minHeight: 400 }}>
            {props.planCard}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}