import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./components/Home";
import Renewable from "./components/Renewable";
import Power from "./components/Power/Power";
import About from "./components/About";
import Contact from "./components/Contact-us";
import Disclousures from "./components/Disclousures";
import NarrowSearch from "./components/NarrowSearch";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/renewable" element={<Renewable/>}/>
        <Route path="/power" element={<Power/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact-us" element={<Contact/>}/>
        <Route path="/disclosures" element={<Disclousures/>}/>
        <Route path="/user_guide" element={<NarrowSearch/>}/>
      </Routes>

    </div>
  );
}

export default App;
