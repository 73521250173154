//Dependences
import React, { useEffect, useState } from "react";
import { Typography, Button, StepLabel, Collapse, AlertTitle, Alert, Box, Stepper, Step } from '@mui/material';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper, Container, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CircularProgress } from "@mui/material";

import { AboutChampions } from "./ChampionsAbout"
import ChampionPayment from "./ChampionPayment";


//Auxiliar functions
import { validateFields } from "../../functions/ValidateForm";
import { parseJson2Server } from "./../../functions/TransformJson";

//Json
import defaultValues from "./json/championDefaultValues.json"
const components = JSON.parse(JSON.stringify(require("./json/championsValues.json")))

const steps = ["SERVICE ADDRESS", "PERSONAL INFO"];
const CHAMPIONS_ENERGY = "championsenergy";

function getStepContent(step, childrenProps) {

  switch (step) {
    case 0:
      return <ChampionPayment components={childrenProps.components} functions={childrenProps.functions} variables={childrenProps.variables} />
    case 1:
      return <AboutChampions components={childrenProps.components} functions={childrenProps.functions} />
    default:
      break;
  }
}


export default function ChampionSignUp(props) {


  //#region setStates.
  const [FirstName, setFirstName] = useState(components.FirstName)
  const [LastName, setLastName] = useState(components.LastName)
  const [Address1, setAddress1] = useState(components.Address1)
  const [Address2, setAddress2] = useState(components.Address2)
  const [City, setCity] = useState(components.City)
  const [ESIID, setESIID] = useState(components.ESIID)
  const [AgentId, setAgentId] = useState(components.AgentId)
  const [BillingAddress1, setBillingAddress1] = useState(components.BillingAddress1)
  const [BillingAddress2, setBillingAddress2] = useState(components.BillingAddress2)
  const [BillingCity, setBillingCity] = useState(components.BillingCity)
  const [BillingStateCode, setBillingStateCode] = useState(components.BillingStateCode)
  const [BillingZipCode, setBillingZipCode] = useState(components.BillingZipCode)
  const [DOB, setDOB] = useState(components.DOB)
  const [SSN, setSSN] = useState(components.SSN)
  const [Email, setEmail] = useState(components.Email)
  const [PrimaryPhone, setPrimaryPhone] = useState(components.PrimaryPhone)
  const [AlternatePhone, setAlternatePhone] = useState(components.AlternatePhone)
  const [IsRenew, setIsRenew] = useState(components.IsRenew)
  const [Ebill, setEbill] = useState(components.Ebill)
  const [LanguagePreference, setLanguagePreference] = useState(components.LanguagePreference)
  const [ReferralId, setReferralId] = useState(components.ReferralId)
  const [RequestedDate, setRequestedDate] = useState(components.RequestedDate)
  const [SendConfirmationEmail, setSendConfirmationEmail] = useState(components.SendConfirmationEmail)
  const [SignupType, setSignupType] = useState(components.SignupType)
  const [NextReadDate, setNextReadDate] = useState(components.NextReadDate)
  const [CreditScore, setCreditScore] = useState(components.CreditScore)
  const [TransactionId, setTransactionId] = useState(components.TransactionId)
  const [ButtonDisabled, setButtonDisabled] = useState(false)
  const [FinalMessage, setFinalMessage] = useState("")
  //#endregion

  //#region onChange
  const onChangeFirstName = (event) => {
    const FirstNameCopy = FirstName;
    FirstNameCopy.value = event.target.value;
    setFirstName(FirstNameCopy)
  }
  const onChangeLastName = (event) => {
    const LastNameCopy = LastName;
    LastNameCopy.value = event.target.value;
    setLastName(LastNameCopy)
  }
  const onChangeAddress1 = (address) => {
    const Address1Copy = Address1;
    Address1Copy.value = address;
    setAddress1(Address1Copy)
  }
  const onChangeAddress2 = (event) => {
    const Address2Copy = Address2;
    Address2Copy.value = event.target.value;
    setAddress2(Address2Copy)
  }
  const onChangeCity = (value) => {
    const CityCopy = City;
    CityCopy.value = value;
    setCity(CityCopy)
  }
  const onChangeESIID = (value) => {
    const ESIIDCopy = ESIID;
    ESIIDCopy.value = value;
    setESIID(ESIIDCopy)
  }
  const onChangeAgentId = (event) => {
    const AgentIdCopy = AgentId;
    AgentIdCopy.value = event.target.value;
    setAgentId(AgentIdCopy)
  }
  const onChangeBillingAddress1 = (event) => {
    const BillingAddress1Copy = BillingAddress1;
    BillingAddress1Copy.value = event.target.value;
    setBillingAddress1(BillingAddress1Copy)
  }
  const onChangeBillingAddress2 = (event) => {
    const BillingAddress2Copy = BillingAddress2;
    BillingAddress2Copy.value = event.target.value;
    setBillingAddress2(BillingAddress2Copy)
  }
  const onChangeBillingCity = (event) => {
    const BillingCityCopy = BillingCity;
    BillingCityCopy.value = event.target.value;
    setBillingCity(BillingCityCopy)
  }
  const onChangeBillingStateCode = (event) => {
    const BillingStateCodeCopy = BillingStateCode;
    BillingStateCodeCopy.value = event.target.value;
    setBillingStateCode(BillingStateCodeCopy)
  }
  const onChangeBillingZipCode = (event) => {
    const BillingZipCodeCopy = BillingZipCode;
    BillingZipCodeCopy.value = event.target.value;
    setBillingZipCode(BillingZipCodeCopy)
  }
  const onChangeDOB = async (date) => {
    // setButtonDisabled(true);
    let DOBCopy = { ...DOB };
    DOBCopy.value = date;
    setDOB(DOBCopy)
  }

  const onChangeSSN = (event) => {
    const SSNCopy = SSN;
    SSNCopy.value = event.target.value;
    setSSN(SSNCopy)
  }
  const onChangeEmail = (event) => {
    const EmailCopy = Email;
    EmailCopy.value = event.target.value;
    setEmail(EmailCopy)
  }
  const onChangePrimaryPhone = (event) => {
    const PrimaryPhoneCopy = PrimaryPhone;
    PrimaryPhoneCopy.value = event.target.value;
    setPrimaryPhone(PrimaryPhoneCopy)
  }
  const onChangeAlternatePhone = (event) => {
    const AlternatePhoneCopy = AlternatePhone;
    AlternatePhoneCopy.value = event.target.value;
    setAlternatePhone(AlternatePhoneCopy)
  }
  const onChangeIsRenew = (checked) => {
    const IsRenewCopy = IsRenew;
    IsRenewCopy.value = checked;
    setIsRenew(IsRenewCopy)
  }
  const onChangeEbill = (event) => {
    const EbillCopy = Ebill;
    EbillCopy.value = event.target.value;
    setEbill(EbillCopy)
  }
  const onChangeLanguagePreference = (event) => {
    const LanguagePreferenceCopy = LanguagePreference;
    LanguagePreferenceCopy.value = event.target.value;
    setLanguagePreference(LanguagePreferenceCopy)
  }
  const onChangeReferralId = (data) => {
    const ReferralIdCopy = ReferralId;
    ReferralIdCopy.value = data;
    setReferralId(ReferralIdCopy)
  }

  const onChangeRequestedDate = (date) => {
    const RequestedDateCopy = RequestedDate;
    RequestedDateCopy.value = date;
    setRequestedDate(RequestedDateCopy)
  }
  const onChangeSendConfirmationEmail = (event) => {
    const SendConfirmationEmailCopy = SendConfirmationEmail;
    SendConfirmationEmailCopy.value = event.target.value;
    setSendConfirmationEmail(SendConfirmationEmailCopy)
  }
  const onChangeSignupType = (event) => {
    const SignupTypeCopy = SignupType;
    SignupTypeCopy.value = event.target.value;
    setSignupType(SignupTypeCopy)
  }
  const onChangeNextReadDate = (event) => {
    const NextReadDateCopy = NextReadDate;
    NextReadDateCopy.value = event.target.value;
    setNextReadDate(NextReadDateCopy)
  }
  const onChangeCreditScore = (event) => {
    const CreditScoreCopy = CreditScore;
    CreditScoreCopy.value = event.target.value;
    setCreditScore(CreditScoreCopy)
  }
  const onChangeTransactionId = (event) => {
    const TransactionIdCopy = TransactionId;
    TransactionIdCopy.value = event.target.value;
    setTransactionId(TransactionIdCopy)
  }
  const onChangeIsDifferenteBillingCity = (checked) => {
    const BillingAddress1Copy = BillingAddress1;
    const BillingCityCopy = BillingCity;
    const BillingStateCodeCopy = BillingStateCode;
    const BillingZipCodeCopy = BillingZipCode;
    BillingAddress1Copy.required = BillingStateCodeCopy.required = BillingCityCopy.required = BillingZipCodeCopy.required = checked

    setBillingAddress1(BillingAddress1Copy);
    setBillingCity(BillingCityCopy)
    setBillingStateCode(BillingStateCodeCopy);
    setBillingZipCode(BillingZipCodeCopy);
  }
  //#endregion

  //#region functionsClidrenProps
  const functionsChildrenProps = {
    onChangeFirstName: onChangeFirstName,
    onChangeLastName: onChangeLastName,
    onChangeAddress1: onChangeAddress1,
    onChangeAddress2: onChangeAddress2,
    onChangeCity: onChangeCity,
    onChangeESIID: onChangeESIID,
    onChangeAgentId: onChangeAgentId,
    onChangeBillingAddress1: onChangeBillingAddress1,
    onChangeBillingAddress2: onChangeBillingAddress2,
    onChangeBillingCity: onChangeBillingCity,
    onChangeBillingStateCode: onChangeBillingStateCode,
    onChangeBillingZipCode: onChangeBillingZipCode,
    onChangeDOB: onChangeDOB,
    onChangeSSN: onChangeSSN,
    onChangeEmail: onChangeEmail,
    onChangePrimaryPhone: onChangePrimaryPhone,
    onChangeAlternatePhone: onChangeAlternatePhone,
    onChangeIsRenew: onChangeIsRenew,
    onChangeEbill: onChangeEbill,
    onChangeLanguagePreference: onChangeLanguagePreference,
    onChangeReferralId: onChangeReferralId,
    onChangeRequestedDate: onChangeRequestedDate,
    onChangeSendConfirmationEmail: onChangeSendConfirmationEmail,
    onChangeSignupType: onChangeSignupType,
    onChangeNextReadDate: onChangeNextReadDate,
    onChangeCreditScore: onChangeCreditScore,
    onChangeTransactionId: onChangeTransactionId,
    onChangeIsDifferenteBillingCity: onChangeIsDifferenteBillingCity
  }
  //#endregion

  //#region variables
  const aboutValues = {
    FirstName: FirstName,
    LastName: LastName,
    DOB: DOB,
    SSN: SSN,
    Email: Email,
    PrimaryPhone: PrimaryPhone,
    AlternatePhone: AlternatePhone,
    LanguagePreference: LanguagePreference,
    ReferralId: ReferralId,
    SendConfirmationEmail: SendConfirmationEmail,
    BillingAddress1: BillingAddress1,
    BillingAddress2: BillingAddress2,
    BillingCity: BillingCity,
    BillingStateCode: BillingStateCode,
    BillingZipCode: BillingZipCode,
    RequestedDate: RequestedDate,
    SignupType: SignupType,
    Ebill: Ebill,
    IsRenew: IsRenew,
  }

  const serviceValues = {
    Address1: Address1,
    City: City,
    Address2: Address2,
    ESIID: ESIID,
  }
  //#endregion variables

  const { zip_code, rateId, ProviderId } = props;

  const [childrenProps, setChildrenProps] = useState(JSON.parse(JSON.stringify(require("./json/championsValues.json"))))

  async function enviarAAxios(childrenProps) {

    const auxiliarJson = parseJson2Server(childrenProps)

    let date_change_DOB = new Date(auxiliarJson.DOB)

    //let date_new_DOB=`${date_change_DOB.getUTCMonth()+1}/${date_change_DOB.getUTCDate()}/${date_change_DOB.getUTCFullYear()}`

    let date_change_requested_date = new Date(auxiliarJson.RequestedDate)
    //let date_new_requested_date=`${date_change_requested_date.getUTCMonth()+1}/${date_change_requested_date.getUTCDate()}/${date_change_requested_date.getUTCFullYear()}`

    const request = {
      "AffiliateId": 1524,
      "FirstName": auxiliarJson.FirstName,
      "LastName": auxiliarJson.LastName,
      "MiddleInitial": "",
      "Address1": auxiliarJson.Address1.split(",")[0],
      "Address2": auxiliarJson.Address2,
      "City": auxiliarJson.City,
      "StateCode": auxiliarJson.StateCode,
      "ZipCode": zip_code,
      "ESIID": auxiliarJson.ESIID,
      "AgentId": "",
      "BillingAddress1": auxiliarJson.BillingAddress1 !== "" ? auxiliarJson.BillingAddress1.split(",")[0] : auxiliarJson.Address1.split(",")[0],
      "BillingAddress2": auxiliarJson.BillingAddress2 !== "" ? auxiliarJson.BillingAddress2 : auxiliarJson.Address2,
      "BillingCity": auxiliarJson.BillingCity !== "" ? auxiliarJson.BillingCity : auxiliarJson.City,
      "BillingStateCode": auxiliarJson.BillingStateCode !== "" ? auxiliarJson.BillingStateCode : auxiliarJson.StateCode,
      "BillingZipCode": auxiliarJson.BillingZipCode !== "" ? auxiliarJson.BillingZipCode : zip_code,
      "DOB": date_change_DOB.toLocaleDateString(),
      "SSN": auxiliarJson.SSN,
      "SendConfirmationEmail": 1,
      "Email": auxiliarJson.Email,
      "PrimaryPhone": auxiliarJson.PrimaryPhone,
      "AlternatePhone": auxiliarJson.AlternatePhone,
      "IsRenew": auxiliarJson.IsRenew ? 1 : 0,
      "Ebill": auxiliarJson.Ebill,
      "LanguagePreference": auxiliarJson.LanguagePreference,
      "RateId": rateId,
      "ReferralId": auxiliarJson.ReferralId,
      "RequestedDate": date_change_requested_date.toLocaleDateString(),
      "SendConfirmationEmail": auxiliarJson.SendConfirmationEmail,
      "SignupType": auxiliarJson.SignupType,
    }

    let data;
    try {
      await axios.post(`https://powerusapi.herokuapp.com/enrollment/${CHAMPIONS_ENERGY}`, request).then(res => {
        data = res.data;
      })
    }
    catch (error) {
      data = [null, "Server error has occured. Please try it again.\n "]
    }
    if (data === null) data = [null, "Server error has occured. Please try it again.\n"]

    return data;
  }

  useEffect(() => (
    setChildrenProps(JSON.parse(JSON.stringify(require("./json/championsValues.json"))))
  ), [])



  const [activeStep, setActiveStep] = React.useState(0);

  const [ShowAlert, setShowErrorAlert] = useState(false);
  const [AlertLabel, setAlertLabel] = useState("");
  const [ButtonLoading, setButtonLoading] = useState(false);
  const [ShowEndAlert, setShowEndAlert] = useState(false);
  const [Success, setSuccess] = useState(false);

  const handleOpen = (text) => {
    if (text) setAlertLabel(text);
    setShowErrorAlert(true)

    setTimeout(() => {
      setShowErrorAlert(false);
    }, 5000)

  }
  const handleOpenEnd = (text, error = true) => {
    setShowEndAlert(true);
    setSuccess(!error);
    setAlertLabel(text)
  }

  const handleNext = async () => {
    setButtonLoading(true);
    let checkValues = {};
    switch (activeStep) {
      case 0:
        checkValues = serviceValues
        break;
      case 1:
        checkValues = aboutValues
        break;
    }
    const [json, errorExist] = validateFields(checkValues)
    const childrenPropsAux = { ...childrenProps, ...json }
    setChildrenProps(childrenPropsAux)
    if (!errorExist) {
      if (activeStep === steps.length - 1) {
        const [response, axiosError] = await enviarAAxios(childrenPropsAux);
        if (!axiosError) {
          setFinalMessage(response);
          handleOpenEnd("", false);
        }
        else {
          setButtonLoading(false);
          return handleOpenEnd(axiosError, "\nPlease call us at 713 358 5479 for further assistance")
        }
      } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else handleOpen("Missing fields");

    setButtonLoading(false);
  }
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const navigation = useNavigate();


  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <>
      <Collapse in={ShowAlert}>
        <Alert style={{ position: "fixed", top: "0", width: "100%", zIndex: "1000000" }} severity="error">
          <AlertTitle>Error</AlertTitle>
          <strong>{AlertLabel}</strong>
        </Alert>
      </Collapse>
      <Dialog
        open={ShowEndAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowEndAlert(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle >{"Enrollment"}</DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'} id="alert-dialog-slide-description">
            {ButtonLoading ? <div style={{ textAlign: 'center' }}><CircularProgress style={{ marginLeft: 100, marginRight: 100 }} /></div> :
              Success ?
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  <p>{FinalMessage}</p>
                  <h4>If you need further assistance, please call us at 713 358 5479</h4>
                </Alert>
                :
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {AlertLabel}
                </Alert>


            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {Success ?
            <Button onClick={() => { navigation(`/`) }}>Close</Button>
            :
            <Button onClick={() => setShowEndAlert(false)}>Close</Button>
          }

        </DialogActions>
      </Dialog>
      <Container maxWidth={"xl"}>
        <Grid justifyContent="center" container spacing={2} >
          <Grid style={{ maxWidth: 987 }} item xs={12} md={7} lg={10}>
            <Box style={{ paddingTop: 25 }} sx={{ width: "100%" }}>

              <Paper style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 20, padding: 20, position: "relative" }} className={"card"} variant="outlined">
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Paper>
              {/* <Button onClick={enviarAAxios} >ENVIAR A AXIOS AAAGH</Button> */}
              <Paper style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20, paddingLeft: 20, paddingRight: 20, position: "relative" }} className={"card"} variant="outlined">
                {
                  getStepContent(activeStep, { functions: functionsChildrenProps, components: childrenProps, variables: { ZipCode: zip_code, ProviderId: ProviderId, StateCode: defaultValues["StateCode"] } })
                }
              </Paper>
              {activeStep === steps.length ? null :
                <Paper style={{ marginLeft: "auto", marginRight: "auto", marginTop: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10, paddingRight: 20, position: "relative" }} className={"card"} variant="outlined">
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <LoadingButton loading={ButtonLoading} disabled={ButtonDisabled} onClick={handleNext}>{activeStep === steps.length - 1 ? "Finish" : "Next"}</LoadingButton>
                  </Box>
                </Paper>
              }
            </Box >
          </Grid>

          <Grid item xs={2} style={{ minWidth: 270, maxWidth: 400, minHeight: 400 }}>
            {props.planCard}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}