import { FormControl, Radio, RadioGroup, Divider, Collapse, TextField, Checkbox, FormControlLabel, InputLabel, Select, MenuItem } from '@mui/material'
import { DesktopDatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { DesktopDatePicker } from '@mui/lab';
import React, { useEffect } from 'react'
import "./../../styles/form.css"
import { FormHelperText } from '@material-ui/core';
import { get_date } from "./../../functions/DateController"


var maxDate = new Date;
maxDate.setMonth(maxDate.getMonth() + 8);
const todayDate = Date.now();

function AboutTexpo(props) {

  const { components } = props;
  const {onChangeCompetitiveResellerKey, onChangeNeedsNewAccount, onChangeCurrentlyHasService, onChangePromotionCode, onChangeEnrollmentDate, onChangeServiceStartDate, onChangeFirstName, onChangeLastName, onChangeEmail, onChangePhoneNumber, onChangeBAAddressLineOne, onChangeBACity, onChangeBAState, onChangeBAZip, onChangeSLAddressLineOne, onChangeSLCity, onChangeSLState, onChangeSLZip, onChangeSSN, onChangeDateOfBirth, onChangeLanguage, onchangeBAAddressLineOne, onChangeAccountType, onChangePremiseType, onChangeIsDifferenteBillingCity} = props.functions;
  const { ZipCode, StateCode, ProviderId } = props.functions;


  const fullwidth = { gridColumn: "1/9" }, firstPart = { gridColumn: "1/5" }, secondPart = { gridColumn: "5/9" }, twoOfThree = { gridColumn: "1/7" }, threeOfTwo = { gridColumn: "7/9" }, firstThird = { gridColumn: "1/4" }, secondThird = { gridColumn: "4/7" }, thirdThird = { gridColumn: "7/9" };

  const [DateOfBirth, setDOB] = React.useState(null);

  const [Language, setLanguage] = React.useState(components.Language.value);
  const [ServiceStartDate, setServiceStartDate] = React.useState(null);

  const [CurrentlyHasService, setCurrentlyHasService] = React.useState(components.CurrentlyHasService.value);
  const [IsDifferenteBillingCity, setIsDifferenteBillingCity] = React.useState(false)



  const secondBillingAddressContainer = React.useRef(null);
  const BACityContainer = React.useRef(null);

  const changeServiceStartDate = (date) => {
    onChangeServiceStartDate(date)
    setServiceStartDate(date)
  }

  const changeCurrentlyHasService = (event) => {
    setCurrentlyHasService(event.target.checked)
    onChangeCurrentlyHasService(event.target.checked ? 1 : 0);
  }


  const changeDateOfBirth = (date) => {
    onChangeDateOfBirth(date)
    setDOB(date)
  }

  const changeLanguage = (event) => {
    onChangeLanguage(event)
    setLanguage(event.target.value);
  }

  return (
    <FormControl id="grid_ChampionsForm">
      <h2 id="title" style={fullwidth}>{"Contact Information"}</h2>
      <Divider id='m-divider' style={fullwidth} />
      <TextField
        select={props.disabled}
        disabled={props.disabled}
        style={firstPart}
        defaultValue={components.FirstName.value}
        required={components.FirstName.required}
        error={components.FirstName.error}
        onChange={onChangeFirstName}
        helperText={components.FirstName.helperText}
        label={components.FirstName.label}
      />
      <TextField
        disabled={props.disabled}
        style={secondPart}
        defaultValue={components.LastName.value}
        required={components.LastName.required}
        error={components.LastName.error}
        onChange={onChangeLastName}
        helperText={components.LastName.helperText}
        label={components.LastName.label}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={props.disabled}
          label={components.DateOfBirth.label}  
          value={DateOfBirth}
          openTo="year"
          views={['year', 'month', 'day']}
          error={components.DateOfBirth.error}
          // maxDate={get_date(-18)}
          
          onChange={changeDateOfBirth}
          renderInput={(params) => <TextField disabled={props.disabled}  required={components.DateOfBirth.required} helperText={components.DateOfBirth.helperText} error={components.DateOfBirth.error} {...params} style={fullwidth} />}
        />
      </LocalizationProvider>
      <TextField
        disabled={props.disabled}
        style={fullwidth}
        defaultValue={components.Email.value}
        required={components.Email.required}
        error={components.Email.error}
        onChange={onChangeEmail}
        helperText={components.Email.helperText}
        label={components.Email.label}
      />
      <TextField
        disabled={props.disabled}
        style={fullwidth}
        defaultValue={components.PhoneNumber.value}
        required={components.PhoneNumber.required}
        error={components.PhoneNumber.error}
        onChange={onChangePhoneNumber}
        helperText={components.PhoneNumber.helperText}
        label={components.PhoneNumber.label}
      />

      <FormControl style={fullwidth}>
        <InputLabel>{components.Language.label}</InputLabel>
        <Select
          disabled={props.disabled}
          value={Language}
          defaultValue={components.Language.value}
          label={components.Language.label}
          onChange={changeLanguage}
          error={components.Language.error}
        >
          <MenuItem value={1}>English</MenuItem>
          <MenuItem value={2}>Spanish</MenuItem>
        </Select>
      </FormControl>


      <FormControl style={{ ...fullwidth }}>
        <FormControlLabel
          disabled={props.disabled}
          checked={IsDifferenteBillingCity}
          defaultChecked={IsDifferenteBillingCity}
          label={"Is the billing address different from the service address? "}
          onChange={(event) => {
            setIsDifferenteBillingCity(event.target.checked)
            onChangeIsDifferenteBillingCity(event.target.checked)
          }}
          control={<Checkbox />} />
      </FormControl>



      <Collapse orientation="down" style={{ ...fullwidth, marginBottom: "5px" }} in={IsDifferenteBillingCity} container={BACityContainer.current} collapsedSize={0}>
        <div id="grid_ChampionsForm" style={{ width: "100%" }} ref={BACityContainer}>
          <h3 id="title" style={fullwidth}>Billing Address</h3>
          <Divider id='m-divider' style={fullwidth} />

          <TextField
            disabled={props.disabled}
            style={fullwidth}
            defaultValue={components.BAAddressLineOne.value}
            required={components.BAAddressLineOne.required}
            error={components.BAAddressLineOne.error}
            helperText={components.BAAddressLineOne.helperText}
            label={components.BAAddressLineOne.label}
            onChange={onchangeBAAddressLineOne}
          />

          <TextField
            disabled={props.disabled}
            style={firstThird}
            defaultValue={components.BACity.value}
            required={components.BACity.required}
            error={components.BACity.error}
            onChange={onChangeBACity}
            helperText={components.BACity.helperText}
            label={components.BACity.label}
          />
          <TextField
            disabled={props.disabled}
            style={secondThird}
            defaultValue={components.BAState.value}
            required={components.BAState.required}
            error={components.BAState.error}
            onChange={onChangeBAState}
            helperText={components.BAState.helperText}
            label={components.BAState.label}
          />
          <TextField
            disabled={props.disabled}
            style={thirdThird}
            defaultValue={components.BAZip.value}
            required={components.BAZip.required}
            error={components.BAZip.error}
            onChange={onChangeBAZip}
            helperText={components.BAZip.helperText}
            label={components.BAZip.label}
          />
        </div>

      </Collapse>

      {/* <h2 style={{ textAlign: "center", ...fullwidth }}>{"Are you moving or switching providers? (Choose one)"}</h2> */}
      {/* <RadioGroup
        style={{ ...fullwidth, marginBottom: "13px", width: "70%", marginLeft: "auto", marginRight: "auto" }}
        defaultValue={components.SignupType.value}
        label={components.SignupType.label}
        onChange={onChangeSignupType}
        error={components.SignupType.error}
      >
        <FormControlLabel style={{ marginLeft: 20 }} value={1} control={<Radio disabled={props.disabled} />} label="I am MOVING and need new service" />
        <FormControlLabel style={{ marginLeft: 20 }} value={0} control={<Radio disabled={props.disabled} />} label="Switching from another provider" />
        <FormControlLabel style={{ marginLeft: 20 }} value={2} control={<Radio disabled={props.disabled} />} label="Standard Switching" />

      </RadioGroup> */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={components.ServiceStartDate.label}
          value={ServiceStartDate}
          disabled={props.disabled}
          disablePast
          disableHighlightToday
          maxDate={maxDate}
          openTo="month"
          views={['month', 'day']}
          minDate={todayDate}
          onChange={changeServiceStartDate}
          renderInput={(params) => <TextField disabled={props.disabled} required={components.ServiceStartDate.required} {...params} style={{ ...fullwidth, width: "70%", margin: "auto" }} />}
        />
        {components.ServiceStartDate.error ? <FormHelperText error style={{ ...fullwidth, width: "70%", margin: "auto" }} >{components.ServiceStartDate.helperText}</FormHelperText> : null}
      </LocalizationProvider>

      <FormControl style={{ ...fullwidth, width: "70%", margin: "auto" }}>
        <FormControlLabel
          disabled={props.disabled}
          style={thirdThird}
          defaultChecked={components.CurrentlyHasService.value}
          label={components.CurrentlyHasService.label}
          control={<Checkbox
            checked={CurrentlyHasService}
            error={components.CurrentlyHasService.error}
            onChange={changeCurrentlyHasService} />} />
      </FormControl>



      <h3 id="title" style={fullwidth}>Confirm Your Identity</h3>
      <TextField
        disabled={props.disabled}
        style={{ ...fullwidth }}
        defaultValue={components.SSN.value}
        required={components.SSN.required}
        error={components.SSN.error}
        onChange={onChangeSSN}
        helperText={components.SSN.helperText}
        label={components.SSN.label}
      />



      <p style={fullwidth}>You should receive a copy of all documents by US mail or email within 2 weeks. You can request an additional copy to be sent to you at any time. You may also print a copy of these documents now by clicking on the links above.</p>









    </FormControl>

  )

}

export { AboutTexpo }