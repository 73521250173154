function isNumber(number) {
  const numberExp = /^[0-9]+$/;
  return number.match(numberExp) !== null;
}

function hasMaxLength(string, max_length) {
  return string.length <= max_length
}

function hasMinLength(string, min_length) {
  return string.length >= min_length
}

function isEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function isNullValue(value){
  return value === null
}

function isEmpty(value) {
  if (value === "") return true
  return false;
};

export { isNumber, hasMaxLength, hasMinLength, isEmail, isEmpty, isNullValue }