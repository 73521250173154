import { useState, useEffect, useRef } from "react";
import { FormGroup, Grid } from "@material-ui/core";
import { Paper, makeStyles } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import axios from 'axios';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation } from "react-router-dom";
import Slider from '@mui/material/Slider';
import { Container } from "@mui/material";
import { Button } from "@material-ui/core";


import FilterDialog from "../FilterDialog";

import Results from "./Results";

const useStyles = makeStyles((theme) => ({
    hideFilter: {
        "@media (max-width: 959px)": {
            display: "none",
        }
    },
    cardContent: {
        "@media (min-width: 1217px)": {
            display: "flex",
        }
    },
    logo: {
        "@media (max-width: 1216px)": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
        },
    },
    description: {
        "@media (max-width: 1218px)": {
            width: "100%"
        },
        "@media (min-width: 1217px)": {
            width: "40%"
        }
    },
    terms: {
        "@media (min-width: 1282px)": {
            display: "flex",
            width: "30%"
        },
        "@media (max-width: 1215px)": {
            display: "flex",
            width: "100%"
        },
    },
    btnSignUp: {
        "@media (min-width: 500px)": {
            position: "absolute"
        }
    },
    hideLanguage: {
        "@media (max-width: 529px)": {
            display: "none",
        }
    },
    customSelect: {
        color: "#fff",
        height: 40,
        width: 120,
        background: 'rgb(153, 0, 35)',
        borderRadius: 5,
        borderStyle: "none",
        fontSize: 16
    },
    customInput: {
        color: "#fff",
        height: 38,
        width: 116,
        background: 'rgb(153, 0, 35)',
        borderRadius: 5,
        borderStyle: "none",
        fontSize: 16,
        textAlign: "center"
    },
    showFilterIcon: {
        "@media (min-width: 959px)": {
            display: "none",
        }
    },
    planLogo: {
        "@media (max-width: 1216px)": {
            height: 30

        },
        "@media (min-width: 1217px)": {
            width: 200
        },
    },
    containedButton: {
        backgroundColor: "#d40030",
        fontSize: 17,
        textTransform:'none',
        color: "#fff",
        '&:hover': {
            backgroundColor: '#e35a79',
            color: '#fff',
        }
    }

}))



export function Filters(props) {
    const { label, multiple, options, handlers, selected, drawfilterOptions, loading } = props;
    return (
        <FormControl fullWidth>
            <FormLabel align="left" id="demo-radio-buttons-group-label">{label}</FormLabel>
            {multiple ?
                <FormGroup>
                    {loading?<div style={{padding:20}}><LinearProgress/></div>:drawfilterOptions.getCheckBoxOptions(options, handlers, selected)}
                </FormGroup>
                :
                <RadioGroup onChange={handlers} aria-labelledby="demo-radio-buttons-group-label" value={selected} name="radio-buttons-group">
                    {loading?<div style={{padding:20}}><LinearProgress/></div>:drawfilterOptions.getRadioOptions(options)}
                </RadioGroup>
            }

        </FormControl>
    )

}


const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 30,
        label: '30',
    },
    {
        value: 60,
        label: '60',
    }

];

const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
      if (didMount.current) {
        func();
      } else {
        didMount.current = true;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
  };

export const TDU_AREAS = {
     "TX-E-AEPCENTRAL": "AEP Texas Central",
     "TX-E-ONCOR": "Oncor" ,
     "TX-E-AEPNORTH": "AEP Texas North",
     "TX-E-CENTERPOINT": "Centerpoint",
     "TX-E-TNMP": "Texas New Mexico Power" 
}

export const uniqueTDU = (array)=> {
    return [...new Set(array.map(({utility_name})=>utility_name))]
}

export const lista_planes = async (zip) => {
   let planes = await axios.get(`https://powerusapi.herokuapp.com/plans`, { params: { zip_code: zip } })
   return planes.data
}

export default function Power() {


    const classGrid = useStyles();
    const location = useLocation()
    const defaultZipCode = "77511"

    const [plans, setPlans] = useState([]);
    const [uniqueTDU_A, setUniqueTDU_A] = useState([]);
    //const [TDUArea, setTDUArea] = useState("");
    // const [estimatedUse, setEstimatedUse] = useState("");
    //const [filtrados, setFiltrados] = useState({ TDUArea: "", estimatedUse: "", contractLength: [1, 60], planType: [false, false, false], renewable:"", low_to_high:"", renewable_energy: [0, 100], compare:[] })
    const [filtrados, setFiltrados] = useState({ TDUArea: ""||location.state.tdu_area, estimatedUse: "", contractLength: [1, 60], planType: [true, false, false], renewable:"", low_to_high:"", renewable_energy: [0, 100], compare:[] })
    const [loadAnimation, setloadAnimation] = useState(true);
    const [zipCode, setZipCode] = useState((location.state && location.state.zip) || defaultZipCode)
    const [filteredPlans, setFilteredPlans] = useState([]);

    const [compareList, setCompareList] = useState(false);
    //const [contractLength, setContractLength] = useState([1, 60]);

    const getPlans = (zip) => {
        lista_planes(zip).then(plans=>{
            setPlans(plans)
            setloadAnimation(false)
            setUniqueTDU_A(uniqueTDU(plans,"utility_name"))
        })
    }
    const sortPlans = (list) => {
        if (filtrados.TDUArea !== "") {
            list = [...list].filter(l => {
                return (l.utility_name === filtrados.TDUArea)
            })
        }
        if (filtrados.estimatedUse !== "" && filtrados.estimatedUse !== "not_know") {
            list = [...list].sort((a, b) => {
                return a[filtrados.estimatedUse] - b[filtrados.estimatedUse]
            })
        }

        if (filtrados.low_to_high === "hl") {
            list = [...list].sort((a, b) => {
                return  b["kwh_1000"] - a["kwh_1000"]
            })
        }else if(filtrados.low_to_high === "lh"){
            list = [...list].sort((a, b) => {
                return  a["kwh_1000"] - b["kwh_1000"]
            })
        }

        //terms
        list = [...list].filter(l => {
            return (l.term >= filtrados.contractLength[0] && l.term <= filtrados.contractLength[1])
        })
        //renewable energy
        list = [...list].filter(l => {
            return (l.renewable >= filtrados.renewable_energy[0] && l.renewable <= filtrados.renewable_energy[1])
        })
        //compare 
        
        if(filtrados.compare.length>1 && compareList){
            list = [...list].filter(l => {
                return filtrados.compare.includes(l.id.toString())
             })
            setFilteredPlans(list)

        }else{
            setCompareList(false)
        }

        setFilteredPlans(list)
    }
    const comparePlans = () =>{
        setCompareList(true)
    }
    const handlers = {
        changeZipCode: (event) => {
            setZipCode(event.target.value)
        },
        handleChangeTDUArea: (event) => {
            setFiltrados((filtro)=>({ ...filtro, TDUArea: event.target.value }))
        },
        estimatedUse: (event) => {
            setFiltrados((filtro)=>({ ...filtro, estimatedUse: event.target.value }))
        },
        handleContractLength: (event, newValue) => {
            setFiltrados((filtro)=>({ ...filtro, contractLength: newValue }))
        },
        handlePlanType: (position) => {
            const updatePlanFilter = filtrados.planType.map((item, index) =>
                index === position ? !item : item
            )
            setFiltrados((filtro)=>({ ...filtro, planType: updatePlanFilter }))
        },
        handleRenewable: (event) => {
            
            setFiltrados((filtro)=>({ ...filtro, renewable: event.target.value }))
        },
        handleLowToHigh:(event)=>{
            setFiltrados((filtro)=>({...filtro, low_to_high:event.target.value}))
        },
        handleRenewableEnergy: (event, newValue) => {
            setFiltrados((filtro)=>({ ...filtro, renewable_energy: newValue }))
        },
        handleCompare:(event)=>{
            
            let id_plan =event.target.value 
            let comp = filtrados.compare
            if (comp.includes(id_plan)){
                comp= [...comp].filter(item => !(item === id_plan));
            }else{
                comp.push(id_plan)
            }
            //if(comp.length==1)setFilteredPlans(plans)
            setFiltrados((filtro)=>({ ...filtro, compare: comp }))
        }

    }

    const drawfilterOptions = {

        getRadioOptions: (list) => {
            return list.map(({ label, value }, key) => {
                return (
                    <FormControlLabel key={key} align="left" value={value} control={<Radio />} label={label} />
                )
            })
        },
        getCheckBoxOptions: (list, handler, selected) => {
            return list.map(({ label, value }, key) => {
                return (<FormControlLabel key={key} align="left" control={<Checkbox checked={selected[key]} onChange={() => handler(key)} name={value} />} label={label} />)
            })
        }
    }
    const filterOptions = [
        
            {
                label: "TDU area",
                multiple: false,
                handleChange: handlers.handleChangeTDUArea,
                value: filtrados.TDUArea,
                options: uniqueTDU_A.map(function(tdu){
                    return {label:TDU_AREAS[tdu], value:tdu}
                })
            },
            {
                label: "Estimated Use",
                multiple: false,
                handleChange: handlers.estimatedUse,
                value: filtrados.estimatedUse,
                options: [
                    { label: "500 Kwh", value: "kwh_500" },
                    { label: "1000 Kwh", value: "kwh_1000" },
                    { label: "2000 Kwh", value: "kwh_2000" },
                    { label: "I don't know", value: "not_know" }],
            },
            {
                label: "Plan Type",
                multiple: true,
                handleChange: handlers.handlePlanType,
                value: filtrados.planType,
                options: [
                    { label: "Fixed Rate", value: "f_r" },
                    { label: "Variable (Changing Rate)", value: "v_c" },
                    { label: "Indexed (Market Rate)", value: "i_m" }],
            },
            // {
            //     label: "Renewable Energy",
            //     multiple: false,
            //     handleChange: handlers.handleRenewable,
            //     value: filtrados.renewable,
            //     options: [
            //         { label: "0-25%", value: "0-25" },
            //         { label: "26-50%", value: "26-50" },
            //         { label: "51-75%", value: "51-75" },
            //         { label: "76-99%", value: "76-99" },
            //         { label: "100%", value: "100" }],
            // }

        ];

        
    useEffect( () => {
        getPlans(zipCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( () => {
        sortPlans(plans)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans]);

    useDidMountEffect(() => {
        if (zipCode.length >= 5) {
            setloadAnimation(true)
            setFiltrados((filtro) => ({ ...filtro, TDUArea: '', estimatedUse: '',contractLength: [1, 60], renewable_energy: [0, 100] }))
            getPlans(zipCode)
        }
    }, [zipCode]);

    useDidMountEffect(() => {
        sortPlans(plans)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtrados, compareList]);
    



    return (

        <div >
            <div style={{ textAlign: "right", backgroundColor: "#d40030", width: "100%", minWidth: 60, position: "sticky", top: 0, zIndex: 8, display: "flex", justifyContent: "flex-end" }}>
                <div style={{ padding: 10 }}>
                    <label style={{ color: "#fff" }}>Sort By </label>
                    <select onChange={handlers.handleLowToHigh} className={classGrid.customSelect}>
                        <option value={"pw"}>Price/kwh</option>
                        <option value={"lh"}>Low to High</option>
                        <option value={"hl"}>High to Low</option>
                    </select>
                </div>
                <div style={{ padding: 10 }} className={classGrid.hideLanguage}>
                    <label style={{ color: "#fff" }}>Language </label>
                    <select className={classGrid.customSelect}>
                        <option value={1}>English</option>
                        <option value={2}>Spanish</option>
                    </select>
                </div>
                <div style={{ padding: 10 }}>
                    <label style={{ color: "#fff" }}>Zip Code </label>
                    <input onChange={handlers.changeZipCode} type={"number"} defaultValue={ location.state.zip || defaultZipCode} className={classGrid.customInput}></input>
                </div>
                <div className={classGrid.showFilterIcon} style={{ padding: 10 }}>
                    <FilterDialog filterOptions={filterOptions} drawfilterOptions={drawfilterOptions} />
                </div>
            </div>
            <Container style={{paddingTop:30, minHeight:1500}} maxWidth={"xl"}>
                <Grid justifyContent="center" container spacing={5} >
                    <Grid className={classGrid.hideFilter} item xs={2} style={{ minWidth: 270, maxWidth: 400 }}>
                        <Paper style={{ padding: 10 }} className="card" variant="outlined">
                            <Typography align="left" style={{ padding: 5 }} variant="h6">
                                <b>Select Filters</b>
                            </Typography>

                            {filterOptions.map(({ label, multiple, handleChange, options, value }, key) =>

                                <Filters
                                    key={key}
                                    label={label}
                                    multiple={multiple}
                                    options={options}
                                    handlers={handleChange}
                                    selected={value}
                                    loading={loadAnimation}
                                    drawfilterOptions={drawfilterOptions}
                                />
                            )}

                            <FormControl fullWidth>
                                <FormLabel align="left" id="demo-radio-buttons-group-label">Renewable Energy(%)</FormLabel>
                                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                                    <Slider
                                        getAriaLabel={() => 'Renewable Energy'}
                                        value={filtrados.renewable_energy}
                                        onChange={handlers.handleRenewableEnergy}
                                        valueLabelDisplay="auto"
                                        marks={[{value:0,label:"0%"}, {value:50,label:"50%"}, {value:100,label:"100%"}]}
                                        min={0}
                                        max={100}

                                    />
                                </div>
                            </FormControl>

                            <FormControl fullWidth>
                                <FormLabel align="left" id="demo-radio-buttons-group-label">Contract length(Months)</FormLabel>
                                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                                    <Slider
                                        getAriaLabel={() => 'Contract'}
                                        value={filtrados.contractLength}
                                        onChange={handlers.handleContractLength}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        min={1}
                                        max={60}

                                    />
                                </div>
                            </FormControl>

                        </Paper>
                    </Grid>

                    <Grid style={{ maxWidth: 987 }} item xs={12} md={7} lg={10}>

                        {loadAnimation ? <Paper style={{ padding: 20 }} className="card" variant="outlined">
                            <LinearProgress />
                        </Paper> : <Results filteredPlans={filteredPlans} zip={zipCode} compare={filtrados.compare} handlers={handlers} />
                        }

                    </Grid>

                </Grid>
            </Container>
            {filtrados.compare.length>1?
                <div style={{ textAlign: "center", backgroundColor: "#FFF", width: "100%", minWidth: 60, position: "sticky", bottom: 0, zIndex: 8, display: "flex", justifyContent: "flex-end" }}>
                    <div style={{padding:10, margin:'auto'}}><Button onClick={() => { comparePlans() }} className={classGrid.containedButton} fullWidth variant="contained" disableElevation>Compare {filtrados.compare.length}</Button></div>
                </div>
            
            :''}
        </div>
    );
}