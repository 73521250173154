import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Paper } from '@mui/material';
import { Container } from '@mui/material';
import { TextField, FormControl, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { Button as Cbutton } from "@material-ui/core";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { FormGroup } from '@mui/material';
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    containedButton: {
        backgroundColor: "#d40030",
        fontSize: 17,
        textTransform: 'none',
        height: 55,
        color: "#fff",
        '&:hover': {
            backgroundColor: '#e35a79',
            color: '#fff',
        }
    }
}));

const MonthlyUse = () => {

    const classes = useStyles()
    return (
        <div style={{ padding: 50 }}>
            <div style={{ textAlign: "left" }}>
                <h2>1. ON AVERAGE, HOW MUCH ELECTRICITY DO YOU USE IN A MONTH?</h2>
                <FormControl style={{ width: 250 }}>

                    <TextField
                        select // tell TextField to render select
                        label="Select Your Usage"
                        defaultValue={""}
                    >
                        <MenuItem value={"kwh_500"}>500 Kwh</MenuItem>
                        <MenuItem value={"kwh_1000"}>1000 Kwh</MenuItem>
                        <MenuItem value={"kwh_2000"}>2000 Kwh</MenuItem>
                        <MenuItem value={"kwh_2000"}>I don't know</MenuItem>
                    </TextField>
                </FormControl>
                <FormControl style={{ marginLeft: 20 }}>
                    <Cbutton

                        className={classes.containedButton}
                        fullWidth variant="contained"
                        disableElevation>
                        View Plans <ArrowForwardIcon />
                    </Cbutton>
                </FormControl>
            </div>
            <div style={{ textAlign: "left" }}>
                <h2>DID YOU KNOW?</h2>
                <ul>
                    <li>
                        Check past bills to estimate your average electricity use. Usage may be higher during very hot or cold months, so look at several bills to get an idea of your average usage.
                    </li>
                    <li>
                        A kilowatt-hour is equivalent to 1 kilowatt (1kW) of power used for 1 hour (1h) of time. One 100-watt light bulb burning for 10 hours uses 1,000 watt-hours or 1 kilowatt-hour.
                    </li>
                    <li>
                        Most households use an average of 1,000 kWh per month.
                    </li>
                    <li>
                        The total amount of electricity used per month can affect the rate you pay.
                    </li>
                    <li>
                        Many electric companies require that you use a minimum amount of electricity each month. Our shopping tool allows you to see if a company requires a minimum usage and if there is an associated fee or credit.
                    </li>
                </ul>

            </div>

        </div>
    );
}

const PlanTypes = () => {
    const classes = useStyles()
    return (
        <div style={{ padding: 50 }}>
            <div style={{ textAlign: "left" }}>
                <h2>2. WHAT PLAN TYPES ARE YOU INTERESTED IN?</h2>
                <div style={{ display: "flex" }}>
                    <FormGroup style={{ marginLeft: 20 }}>
                        <FormControlLabel control={<Checkbox />} label="Fixed Rate" />
                        <FormControlLabel control={<Checkbox />} label="Variable (Changing Rate)" />
                        <FormControlLabel control={<Checkbox />} label="Indexed (Market Rate)" />
                    </FormGroup>
                    <FormControl style={{ marginLeft: 20 }}>
                        <Cbutton

                            className={classes.containedButton}
                            fullWidth variant="contained"
                            disableElevation>
                            View Plans <ArrowForwardIcon />
                        </Cbutton>
                    </FormControl>
                </div>
            </div>
            <div style={{ textAlign: "left" }}>
                <h2>DID YOU KNOW?</h2>
                <ul>
                    <li>
                        <strong>Fixed-Rate Plan</strong> A fixed-rate plan has a set rate that doesn't change throughout the contract period, with minor exceptions. If you choose a plan with a fixed rate, your price per kWh will not change during your contract period except for changes in Transmission and Distribution fees, changes in ERCOT or Texas Regional Entity administrative fees, or changes resulting from federal, state or local laws that impose fees beyond your REP’s control. This may help your household budgeting, but if market prices fall you may have to wait until your contract ends to enjoy a lower price.
                    </li>
                    <li>
                        <strong>Indexed Rate Plan</strong> An indexed rate plan (also called market rate plan) is similar to a variable plan in that the price per kWh can go up or down each month. The difference is that the rates for these plans are directly tied to a pricing formula connected to a publicly available index. If the index rises, your monthly rate will also, but if the index falls, your rates will be lower.
                        <br />
                        <br />
                        With an indexed plan, the price per kWh can change substantially each month, giving benefits and risks similar to those of variable rate plans. The difference is that the rates for indexed rate plans are tied to a publicly available index. Customers considering these plans should ask the electric company for specifics about the pricing formula as well as how and when they will receive notifications of changes to the index.
                    </li>
                    <li>
                        <strong>Variable Rate Plans</strong> Variable rate plans have no monthly contract or cancellation fee, but the rate you pay per kWh can vary from month to month. Your rate can go up or down based on the market and the discretion of your electric company. Variable plans allow customers to benefit from falling market prices, but they also have an increased risk for higher rates if electricity prices spike due to natural disasters, cold winters, or adverse market conditions. The rates different companies charge each month varies, but because customers can switch any time, companies have an incentive to keep their rates low.
                    </li>
                </ul>

            </div>

        </div>
    );
}

const ContractLength = () => {
    const classes = useStyles()
    return (
        <div style={{ padding: 50 }}>
            <div style={{ textAlign: "left" }}>
                <h2>3. HOW LONG DO YOU WANT YOUR CONTRACT TO LAST?</h2>
                <FormControl style={{ width: 100 }}>
                    <TextField
                        // tell TextField to render select
                        label=""
                        defaultValue={""}
                    >

                    </TextField>
                </FormControl>
                <FormControl style={{ height: 55, margin: 10 }}>
                    TO
                </FormControl>

                <FormControl style={{ width: 100 }}>
                    <TextField
                        // tell TextField to render select
                        label=""
                        defaultValue={""}
                    >

                    </TextField>
                </FormControl>
                <FormControl style={{ height: 55, margin: 10 }}>
                    Months
                </FormControl>

                <FormControl style={{ marginLeft: 20 }}>
                    <Cbutton

                        className={classes.containedButton}
                        fullWidth variant="contained"
                        disableElevation>
                        View Plans <ArrowForwardIcon />
                    </Cbutton>
                </FormControl>
            </div>
            <div style={{ textAlign: "left" }}>
                <h2>DID YOU KNOW?</h2>
                <ul>
                    <li>
                        If you choose a plan with a long contract period, you may be subject to an early cancellation fee if you decide to change plans prior to the expiration of the contract period.
                    </li>
                    <li>
                        Plans that are shorter give you more flexibility to switch, but they only lock in your set rate for a short amount of time.
                    </li>
                    <li>
                        Month-to-Month plans are usually Variable or Indexed offers.
                    </li>
                </ul>

            </div>

        </div>
    );
}

const ShoppingFAQS = () => {
    const classes = useStyles()
    return (
        <div style={{ padding: 50 }}>
            <div style={{ textAlign: "left" }}>
                <h2>ABOUT MINIMUM ELECTRICITY USAGE</h2>
                <div>
                    <span>
                        Many plans require you to use a minimum amount of electricity each month. If you use less than the minimum amount, you will automatically be charged a fee, sometimes called a "minimum usage charge." This fee may or may not be listed separately on your monthly bill, so it is important to check the electricity facts label for your plan. Not all companies charge this fee or require you to use a minimum amount each month. Some companies offer credits or waivers of other fees for using a certain amount of electricity. Our shopping tool allows you to see if a company requires a minimum electricity usage and if there is an associated fee or credit.
                    </span>

                    <div style={{ marginTop: 20 }}>
                        <FormControl>
                            <Cbutton

                                className={classes.containedButton}
                                fullWidth variant="contained"
                                disableElevation>
                                View Plans <ArrowForwardIcon />
                            </Cbutton>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: "left" }}>
                <h2>DID YOU KNOW?</h2>
                <ul>
                    <li>
                        <strong>Does the rate include all costs?</strong>
                        <br />
                        The average kWh rate includes the energy rate, transmission and distribution charges, the monthly customer charge, and any other recurring fees. Make sure to ask about non-recurring fees and other charges and taxes not included in the average kWh rate to estimate your monthly bill.
                    </li>
                    <li>
                        <strong>Does the plan require a minimum electricity usage?</strong>
                        <br />
                        Many electric companies require that you use a minimum amount of electricity each month. Our shopping tool allows you to see if a company requires a minimum electricity usage and if there is an associated fee or credit.

                    </li>
                    <li>
                        <strong>What are my payment options?</strong>
                        <br />
                        Check with the electric company selling the plan. Most companies offer many different payment options, including electronic billing and auto payments.
                    </li>
                    <li>
                        <strong>What happens when my contract period expires?</strong>
                        <br />
                        Many plans will default to a month-to-month basis if you allow your existing contract to expire without having a new contract in place. The month-to-month default price will likely be higher. Be sure to have a new contract in place before your current contract expires.
                    </li>
                    <li>
                        <strong>What if I miss a payment?</strong>
                        <br />
                        Electric companies have different policies. Some companies are more flexible than others. Ask about your company’s policy or read the terms of service agreement.
                    </li>
                    <li>
                        <strong>Is there a penalty if I break the contract?</strong>
                        <br />
                        Most fixed rate contracts have a penalty for switching to another company. Check with the company to find out the penalties or check the terms of service agreement for the plan in which you are interested.
                    </li>
                </ul>

            </div>
        </div>
    )
}




const steps = ['Average Monthly Use', 'Plan Types', 'Contract Length', 'Shopping FAQs'];

export default function NarrowSearch() {
    const navigation = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <MonthlyUse />
            case 1:
                return <PlanTypes />
            case 2:
                return <ContractLength />
            default:
                return <ShoppingFAQS />
        }
    }

    return (
        <Container sx={{ width: '100%' }} style={{ padding: 30 }}>
            <Paper className={"card"} style={{ padding: 20 }}>
                <Stepper activeStep={activeStep} >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

            </Paper>
            <React.Fragment>
                <Paper className='card' style={{ marginTop: 20 }}>
                    {getStepContent(activeStep)}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep === steps.length - 1 ?
                            <Button onClick={()=>{navigation(`/power`)}}>
                                Finish
                            </Button>
                            :
                            <Button onClick={handleNext}>
                                Next
                            </Button>
                        }

                    </Box>
                </Paper>

            </React.Fragment>

        </Container>
    );
}